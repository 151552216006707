import {
  SET_REPAIRS,
  SET_REPAIR_ADD_FIX,
  SET_REPAIR_REMOVE_FIX,
  SET_REPAIR_ADD_FUSE,
  SET_REPAIR_REMOVE_FUSE,
} from "../actionTypes/repairActionTypes";

const initialState = {
  fixes: [],
  fuses: [],
};


const repairReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPAIRS:
      return {
        fixes: [],
        fuses:[],
      };
    case SET_REPAIR_ADD_FIX:
      return {
        ...state,
        fixes: [...state.fixes, action.payload],
      };
    case SET_REPAIR_REMOVE_FIX:
      const updatedFixes = state.fixes.filter(fix => fix.id !== action.payload.id);
      return {
        ...state,
        fixes: updatedFixes,
      };
    case SET_REPAIR_ADD_FUSE:
      return {
        ...state,
        fuses: [...state.fuses, action.payload],
      };
    case SET_REPAIR_REMOVE_FUSE:
      return {
        ...state,
        fuses: state.fuses.filter(fuse => fuse.id !== action.payload.id),
      };    default:
    return state;
  }
};

export default repairReducer;
