import React from "react";
import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/userActions";

import { IconChevronDown } from "@tabler/icons-react";
import Logo from "../../assets/logo/logo.svg";
import icons1 from "../../assets/icons/icon1.svg";
import icons2 from "../../assets/icons/icon2.svg";
import icons3 from "../../assets/icons/icon3.svg";
import icons4 from "../../assets/icons/icon4.svg";
import Group297 from "../../assets/img/Group297.png";
import Group299 from "../../assets/img/Group299.png";
import Group300 from "../../assets/img/Group300.png";
import Group304 from "../../assets/img/Group304.png";
import Layer from "../../assets/img/Layer.png";
import Layer1 from "../../assets/img/Layer1.png";
import Laptop from "../../assets/img/laptop.png";
import Group307 from "../../assets/img/Group307.png";
import Elipse from "../../assets/img/Ellipse74.png";
import Company25 from "../../assets/img/company25.png";
import Deltafixpro from "../../assets/img/deltafixpro.png";
import question from "../../assets/img/question.png";
import Delivery from "../../assets/img/delivery.png";
import Map from "../../assets/img/map.png";
import Coin from "../../assets/img/coin.png";
import Check from "../../assets/img/Check.png";
import Certificate from "../../assets/img/certificate.png";
import UserOultine from "../../assets/img/UserOultine.png";
import Stairs from "../../assets/img/stairs.png";
import Pen from "../../assets/img/pen.png";
import Carrier from "../../assets/img/carrier.png";
import quiz from "../../assets/img/quiz.png";
import classes from "./header.css";

const mockdata = [
  {
    title: "CEP TELEFONU",
  },
  {
    title: "ROBOT SÜPÜRGE",
  },
  {
    title: "ŞARJLI SÜPÜRGE",
  },
  {
    title: "AKILLI SAAT",
  },
  {
    title: "SCOOTER",
  },
  {
    title: "BLUETOOTH HOPARLÖR",
  },
];

function Header() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setUser(null));
  };

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  const links = mockdata.map((item, index) => (
    <div style={{ paddingTop: "30px" }} key={index}>
      <UnstyledButton className={classes.subLink} key={item.title}>
        <Group wrap="nowrap" align="flex-start">
          <img src={Elipse} />
          <Text color="#ffffff" size="sm" fw={500}>
            {item.title}
          </Text>
        </Group>
      </UnstyledButton>
    </div>
  ));

  return (
    <Box className="sticky-top header-container">
      <div className="container-fluid">
        <header className={classes.header}>
          <Group className="header-div" h="100%">
            <div className="header-container">
              <Link to="/">
                <img src={Logo} height={40} width={215} alt="delta-fix-logo" />
              </Link>
            </div>
            <Group className="header-nav" h="100%" gap={0}>
              <HoverCard
                width={1200}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <div className="nav-item-div">
                    <img src={icons1} alt="icon1" />
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box className="category-title" component="span" mr={5}>
                          KATEGORİLER
                        </Box>
                      </Center>
                    </a>
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  bg="#121212"
                  style={{ overflow: "hidden", border: "none" }}
                >
                  <Group justify="space-between" px="md" pt={20}>
                    <Text fw={500} color="#E51A4B" fz={18}>
                      Kategoriler
                    </Text>
                  </Group>
                  <Group px="md">
                    <div className="category-container w-100">
                      <div className={classes.dropdownFooter}>
                        <div
                          className="category-div"
                          style={{ marginTop: "-70px" }}
                        >
                          <img src={Elipse} />
                          <Text fz={16} color="white" ml={10}>
                            CEP TELEFONU
                          </Text>
                        </div>

                        <div>
                          <div className="category-div">
                            <img src={Elipse} />
                            <Text fz={16} color="white" ml={10}>
                              ROBOT SÜPÜRGE
                            </Text>
                          </div>
                        </div>
                        <div>
                          <div className="category-div">
                            <img src={Elipse} />
                            <Text fz={16} color="white" ml={10}>
                              ŞARJLI SÜPÜRGE
                            </Text>
                          </div>
                        </div>
                        <div>
                          <div className="category-div">
                            <img src={Elipse} />
                            <Text fz={16} color="white" ml={10}>
                              AKILLI SAAT
                            </Text>
                          </div>
                          <div></div>
                          <div className="category-div">
                            <img src={Elipse} />
                            <Text fz={16} color="white" ml={10}>
                              BLUETOOTH HOPARLÖR
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="deltafixpro">
                        <img src={Deltafixpro} />
                      </div>
                    </div>
                  </Group>
                </HoverCard.Dropdown>
              </HoverCard>
              <HoverCard
                width={1200}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <div className="nav-item-div">
                    <img src={icons2} />
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box className="services-title" component="span" mr={5}>
                          HİZMETLER
                        </Box>
                      </Center>
                    </a>
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  bg="#121212"
                  style={{ overflow: "hidden", border: "none" }}
                >
                  <div className="services-section">
                    <div>
                      <Group justify="space-between" px="md" pt={20}>
                        <Text fw={500} color="#E51A4B" fz={18}>
                          Hizmetler
                        </Text>
                      </Group>
                      <Group px="md">
                        <div className={classes.dropdownFooter}>
                          <div className="services-body">
                            <div className="services-div">
                              <div className="services">
                                <img src={Group297} />
                                <Text fz={16} color="white" ml={10}>
                                  Ekran Onarımı
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Group299} />
                                <Text fz={16} color="white" ml={10}>
                                  Batarya Değişimi
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Group300} />
                                <Text fz={16} color="white" ml={10}>
                                  Kamera Tamiri
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Layer} />
                                <Text fz={16} color="white" ml={10}>
                                  Hoparlör Tamiri
                                </Text>
                              </div>
                            </div>
                            <div>
                              <div className="services">
                                <img src={Group304} />
                                <Text fz={16} color="white" ml={10}>
                                  Anakart Onarımı
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Layer1} />
                                <Text fz={16} color="white" ml={10}>
                                  Akıllı Saat Tamiri
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Laptop} />
                                <Text fz={16} color="white" ml={10}>
                                  MacBook Tamiri
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Group307} />
                                <Text fz={16} color="white" ml={10}>
                                  Robot Süpürge Onarımı
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img />
                        </div>
                      </Group>
                    </div>
                    <div>
                      <Group justify="space-between" px="md" pt={20}>
                        <Text fw={500} color="#E51A4B" fz={18}>
                          DeltaFix Kurumsal
                        </Text>
                      </Group>
                      <Group px="md">
                        <div className={classes.dropdownFooter}>
                          <div className="services-body">
                            <div className="services-div">
                              <div className="services">
                                <img src={Company25} />
                                <Text fz={16} color="white" ml={10}>
                                  Hakkımızda
                                </Text>
                              </div>
                              <div className="services">
                                <img src={question} />
                                <Text fz={16} color="white" ml={10}>
                                  Neden Biz?
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Delivery} />
                                <Text fz={16} color="white" ml={10}>
                                  Adrese Kurye
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Map} />
                                <Text fz={16} color="white" ml={10}>
                                  Yerinde Kurye
                                </Text>
                              </div>
                            </div>
                            <div className="services-about">
                              <div className="services">
                                <img src={Coin} />
                                <Text fz={16} color="white" ml={10}>
                                  Ücretli Onarım
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Check} />
                                <Text fz={16} color="white" ml={10}>
                                  Referanslar
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Certificate} />
                                <Text fz={16} color="white" ml={10}>
                                  Sertifikalar
                                </Text>
                              </div>
                              <div className="services">
                                <img src={UserOultine} />
                                <Text fz={16} color="white" ml={10}>
                                  Ekibimiz
                                </Text>
                              </div>
                            </div>
                            <div>
                              <div className="services">
                                <img src={Stairs} />
                                <Text fz={16} color="white" ml={10}>
                                  Kariyer
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Pen} />
                                <Text fz={16} color="white" ml={10}>
                                  KVKK ve Gizlilik Sözleşmesi
                                </Text>
                              </div>
                              <div className="services">
                                <img src={Carrier} />
                                <Text fz={16} color="white" ml={10}>
                                  Anlaşmalı Kurumlar
                                </Text>
                              </div>
                              <div className="services">
                                <img src={quiz} />
                                <Text fz={16} color="white" ml={10}>
                                  Sıkça Sorulan Sorular
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img />
                        </div>
                      </Group>
                    </div>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
              <div className="nav-item-div">
                <img src={icons3} />
                <Link to="/#" className={classes.link}>
                  İLETİŞİM
                </Link>
              </div>
              <Link className="menu-nav-pricing" to="/products">
                <img src={icons4} />
                ÜCRETLENDİRME
              </Link>
              <HoverCard
                width={260}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <div className="transactions-button-div">
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box component="span" mr={5}>
                          ONLİNE İŞLEMLER
                        </Box>
                      </Center>
                    </a>
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  bg="#121212"
                  style={{ overflow: "hidden", border: "none" }}
                >
                  <Group px="md">
                    <div className={classes.dropdownFooter}>
                      <div>
                        {user ? (
                          <>
                            <div>
                              <Link to="/user/account">
                                <Button
                                  className="login-btn"
                                  bg="#121212"
                                  w={200}
                                  mb={15}
                                  radius="10px"
                                >
                                  <Text
                                    fz={16}
                                    color="#FFFFFF"
                                    ta="center"
                                    p={30}
                                  >
                                    HESABIM
                                  </Text>
                                </Button>
                              </Link>
                            </div>
                            <div>
                              <Button
                                onClick={handleLogout}
                                className="login-member"
                                w={200}
                                mb={15}
                              >
                                <Text
                                  fz={16}
                                  color="#000000"
                                  ta="center"
                                  p={30}
                                >
                                  ÇIKIŞ YAP
                                </Text>
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <Link to="user/register">
                                <Button
                                  className="login-member"
                                  w={200}
                                  mb={15}
                                  mt={20}
                                >
                                  <Text
                                    fz={16}
                                    color="#000000"
                                    ta="center"
                                    p={30}
                                  >
                                    ÜYE OL
                                  </Text>
                                </Button>
                              </Link>
                            </div>
                            <div>
                              <Link to="/user/login">
                                <Button
                                  className="login-btn"
                                  bg="#121212"
                                  w={200}
                                  mb={15}
                                  radius="10px"
                                >
                                  <Text
                                    fz={16}
                                    color="#FFFFFF"
                                    ta="center"
                                    p={30}
                                  >
                                    ÜYE GİRİŞİ
                                  </Text>
                                </Button>
                              </Link>
                            </div>
                            <div>
                              <Link to="/user/login-dealer">
                                <Button
                                  className="login-btn"
                                  bg="#121212"
                                  w={200}
                                  mb={15}
                                  radius="10px"
                                >
                                  <Text
                                    fz={16}
                                    color="#FFFFFF"
                                    ta="center"
                                    p={30}
                                  >
                                    BAYİ GİRİŞİ
                                  </Text>
                                </Button>
                              </Link>
                            </div>
                          </>
                        )}
                        <div>
                          <Link to="/product-inquiry">
                            <Button
                              className="login-btn"
                              bg="#121212"
                              w={200}
                              mb={15}
                              radius="10px"
                            >
                              <Text
                                fz={16}
                                color="#FFFFFF"
                                pb={20}
                                pt={30}
                              ></Text>
                              ONLİNE SORGULAMA
                            </Button>
                          </Link>
                        </div>
                        <div>
                          <Button
                            className="login-btn"
                            bg="#121212"
                            w={200}
                            mb={20}
                            radius="10px"
                          >
                            <Text fz={16} color="#FFFFFF" ta="center" p={30}>
                              BİZE ULAŞIN
                            </Text>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Group>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <Burger
              className="hello"
              opened={drawerOpened}
              onClick={toggleDrawer}
            />
          </Group>
        </header>
      </div>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        zIndex={1000000}
      >
        <ScrollArea
          h={`calc(100vh - ${rem(80)})`}
          mx="-md"
          className="mini-menu-margin"
        >
          <div className="header-menu-nav" style={{ width: "100%" }}>
            <UnstyledButton
              className={classes.link}
              onClick={toggleLinks}
              style={{
                paddingBottom: "10px",
                borderBottom: "1px solid #707070",
              }}
            >
              <Center inline>
                <Box component="span" fz={16} mr={5}>
                  <Text color="#ffffff" fz={16}>
                    Kategoriler
                  </Text>
                </Box>
                <IconChevronDown style={{ width: rem(16), height: rem(16) }} />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse>
            <Link
              to="#"
              className={classes.link}
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: "16px",
                paddingBottom: "20px",
                borderBottom: "1px solid #707070",
                paddingTop: "20px",
              }}
            >
              Hizmetler
            </Link>
            <Link
              to="/contact"
              className={classes.link}
              onClick={closeDrawer}
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: "16px",
                paddingBottom: "20px",
                borderBottom: "1px solid #707070",
                paddingTop: "20px",
              }}
            >
              İletişim
            </Link>
            {user ? (
              <>
                <Link
                  to="#"
                  className={classes.link}
                  onClick={closeDrawer}
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    fontSize: "16px",
                    paddingBottom: "20px",
                    borderBottom: "1px solid #707070",
                    paddingTop: "20px",
                  }}
                >
                  Hesabım
                </Link>
                <Link
                  to="#"
                  className={classes.link}
                  onClick={handleLogout}
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    fontSize: "16px",
                    paddingBottom: "20px",
                    borderBottom: "1px solid #707070",
                    paddingTop: "20px",
                  }}
                >
                  Çıkış Yap
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/user/register"
                  className={classes.link}
                  onClick={closeDrawer}
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    fontSize: "16px",
                    paddingBottom: "20px",
                    borderBottom: "1px solid #707070",
                    paddingTop: "20px",
                  }}
                >
                  Üye Ol
                </Link>
                <Link
                  to="/user/login"
                  className={classes.link}
                  onClick={closeDrawer}
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    fontSize: "16px",
                    paddingBottom: "20px",
                    borderBottom: "1px solid #707070",
                    paddingTop: "20px",
                  }}
                >
                  Üye Girişi
                </Link>
              </>
            )}
            <div className="menu-nav-button-group">
              <div className="menu-nav-pricing">
                <img src={icons4} />
                <Link to="/products" onClick={closeDrawer} className="link">
                  Ücretlendirme
                </Link>
              </div>
              <HoverCard
                width={260}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <div
                    className="transactions-button-div"
                    style={{ margin: "0px" }}
                  >
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box component="span" className="link" mr={5}>
                          Online Sorgulama
                        </Box>
                      </Center>
                    </a>
                  </div>
                </HoverCard.Target>
              </HoverCard>
            </div>
          </div>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}

export default Header;
