import {
  Container,
  Paper,
  Text,
  TextInput,
  Textarea,
  NativeSelect,
  Button,
} from "@mantine/core";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { useForm, isEmail, hasLength } from "@mantine/form";
import { useSelector } from "react-redux";
import "./profile.css";
import { IMaskInput } from "react-imask";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions/userActions";
import axios from "axios";

import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import city from "../../../utilities/city.json";
import district from "../../../utilities/district.json";

export default function Profile() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);

  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  var [formResponse, setFormResponse] = useState("");

  const cityOptions = city.map(function (item) {
    return {
      label: item.city_name,
      value: item.city_id,
    };
  });

  const dispatch = useDispatch();

  const form = useForm({
    validate: {
      name: hasLength(
        { min: 2, max: 15 },
        "Ad 2-15 karakter uzunluğunda olmalıdır."
      ),
      surname: hasLength(
        { min: 2, max: 20 },
        "Soyad 2-20 karakter uzunluğunda olmalıdır."
      ),
      email: isEmail("E-mail zorunludur."),
      phone: (value) => {
        let unmaskValue = value.replace(/\D/g, "");
        const isValid =
          unmaskValue.startsWith("0") && unmaskValue.length === 11;
        return isValid ? null : "Telefon numarası geçersiz.";
      },
      city: (value) => (value ? null : "İl alanı zorunludur."),
      district: (value) => (value ? null : "İlçe alanı zorunludur."),
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        };

        const article = {
          apikey: "5745321453468",
        };
        const response = await axios.post(
          `https://omega.deltaservis.com.tr/fix/getProfile/`,
          article,
          { headers }
        );
        if (response.data.code === 200) {
          let userData = response.data.data;
          userData.token = user.token;
          dispatch(setUser(userData));
          const selectedCityObject = city.find(
            (item) => item.city_id === userData.city
          );
          setSelectedCity(selectedCityObject);
          form.setValues({
            name: userData.name,
            surname: userData.surname,
            phone: userData.phone,
            email: userData.email,
            city: userData.city,
            district: userData.district,
          });
        }
      } catch (error) {
        console.error("Hata oluştu: ", error);
      }
    };

    fetchData();
  }, [user]);

  const showAlert = (item) => {
    Swal.fire({
      title: "Takip No: " + item.data,
      text: item.message,
      icon: "success",
      confirmButtonText: "Anasayfaya Dön",
      showConfirmButton: true,
    }).then(function () {
      navigate("/");
    });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const isValid = await form.validate();
      if (!isValid.hasErrors && form.values.checkbox) {
        const unmaskedPhone = form.values.phone.replace(/\D/g, "");

        const headers = user?.token
          ? {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            }
          : "";

        const article = {
          guest: {
            email: form.values.email,
            apikey: "5745321453468",
            name: form.values.name,
            city: selectedCity?.city_id,
            district: form.values.district,
            surname: form.values.surname,
            phone: unmaskedPhone,
            address: form.values.address,
          },
        };
        const response = await axios.post(
          "https://omega.deltaservis.com.tr/fix/updateProfile/",
          article,
          { headers }
        );
        setFormResponse("");
        showAlert(response.data);
      } else {
        setFormResponse(
          'Lütfen zorunlu alanları doldurun ve "Cihaz Gönderim Kurallarını" kabul ediniz.'
        );
      }
    } catch (error) {
      setFormResponse(error.response.data.message);
    }
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    const selectedCityObject = city.find((item) => item.city_id === cityId);
    setSelectedCity(selectedCityObject);
  };

  useEffect(() => {
    let filteredDistricts = district
      .filter((district) => district.city_id === selectedCity?.city_id)
      .map((filteredDistrict) => ({
        label: filteredDistrict.district_name,
        value: filteredDistrict.dist_id,
      }));
    filteredDistricts.unshift({
      label: "İlçe Seçiniz",
      value: "",
    });
    setFilteredDistrict(filteredDistricts);
  }, [selectedCity]);

  return (
    <div className="device-shipping-section container-fluid text-center">
      <div className="login-body container-fluid row m-0 mt-5">
        <div className="col">
          <div>
            <Container component="form" onSubmit={onSubmitHandler} size={630}>
              <Paper className="paper-container">
                <Text className="form-title-send-device d-flex">
                  Profilinizi Güncelleyin
                </Text>
                <div className="shipping-form-input">
                  <TextInput
                    w={"100%"}
                    placeholder="Ad"
                    withAsterisk
                    {...form.getInputProps("name")}
                  />
                  <Text color="#E51A4B" mb={20} ml={5}>
                    *
                  </Text>
                </div>
                <div className="shipping-form-input">
                  <TextInput
                    placeholder="Soyad"
                    withAsterisk
                    w={"100%"}
                    mt="md"
                    mb={"md"}
                    {...form.getInputProps("surname")}
                  />
                  <Text color="#E51A4B" mb={20} ml={5}>
                    *
                  </Text>
                </div>
                <div className="shipping-form-input">
                  <TextInput
                    component={IMaskInput}
                    mask="0 (000) 000 00 00"
                    className="phone-input"
                    withAsterisk
                    country={"tr"}
                    maxLength={11}
                    placeholder="Gsm No: 0 (XXX) XXX XX XX"
                    {...form.getInputProps("phone")}
                  />
                  <Text className="required">*</Text>
                </div>
                <div className="shipping-form-input pt-3">
                  <TextInput
                    placeholder="E-Posta"
                    withAsterisk
                    w={"100%"}
                    {...form.getInputProps("email")}
                  />
                  <Text color="#E51A4B" mb={20} ml={5}>
                    *
                  </Text>
                </div>
                <div className="shipping-form-input pt-3">
                  <NativeSelect
                    placeholder="Şehir Seçiniz"
                    withAsterisk
                    w={"100%"}
                    data={cityOptions}
                    {...form.getInputProps("city")}
                    onBlur={handleCityChange}
                  />
                  <Text className="required">*</Text>
                </div>
                <div className="shipping-form-input pt-3">
                  <NativeSelect
                    placeholder="İlçe Seçiniz"
                    {...form.getInputProps("district")}
                    withAsterisk
                    w={"100%"}
                    data={
                      filteredDistrict.length
                        ? filteredDistrict
                        : ["İlçe Seçiniz"]
                    }
                  />
                  <Text className="required">*</Text>
                </div>
                <div className="shipping-form-input pt-3">
                  <Textarea
                    className="short-input"
                    placeholder="Adres"
                    w={"100%"}
                    {...form.getInputProps("address")}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <Text className="d-inline-block" color="#E51A4B">
                    *
                  </Text>
                  <Text
                    className="d-inline-block pt-1"
                    pl={3}
                    fz={12}
                    color="#707070"
                  >
                    Doldurulması zorunlu alanlar
                  </Text>
                </div>
                <Button className="mt-4 w-100 total-price-btn" type="submit">
                  Güncelle
                  <FontAwesomeIcon size="sm" icon={faAnglesRight} />
                </Button>
                <Text className="form-response">{formResponse}</Text>
              </Paper>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
