import { SET_REPAIRS,SET_REPAIR_ADD_FIX,SET_REPAIR_REMOVE_FIX,SET_REPAIR_ADD_FUSE,SET_REPAIR_REMOVE_FUSE } from '../actionTypes/repairActionTypes';

export const setRepairs = (selectedRepairs) => ({
  type: SET_REPAIRS,
  payload: selectedRepairs,
});

export const setAddFix = (selectedRepairs) => ({
  type: SET_REPAIR_ADD_FIX,
  payload: selectedRepairs,
});

export const setRemoveFix = (selectedRepairs) => ({
  type: SET_REPAIR_REMOVE_FIX,
  payload: selectedRepairs,
});

export const setAddFuse = (selectedRepairs) => ({
  type: SET_REPAIR_ADD_FUSE,
  payload: selectedRepairs,
});

export const setRemoveFuse = (selectedRepairs) => ({
  type: SET_REPAIR_REMOVE_FUSE,
  payload: selectedRepairs,
});