import React from "react";
import Slider1 from "../../assets/sliderphone/slider1.webp";
import { BackgroundImage, Box, Button, Text } from "@mantine/core";
import "./repair.css";
import { Link } from "react-router-dom";

export default function RepairSlider() {
  return (
    <Box className="repair-slider-section" maw="100%">
      <BackgroundImage
        className="slider-info-section"
        h="600px"
        src={Slider1}
        radius="sm"
      >
        <div className="sliderinfo">
          <Text className="quick-repair-title" fz={80} color="white" fw="bold">
            Hızlı
          </Text>
          <Text className="quick-repair-title" fz={80} color="white" fw="bold">
            Güvenli
          </Text>
          <Text className="quick-repair-title" fz={80} color="white" fw="bold">
            Ekonomik
          </Text>
          <div className="pricing-button">
            <Link className="pricing-table-text" to={`/products`}>
              <Button className="pricing-table-btn" mt={16}>
                ÜCRETLENDİRME TABLOSU
              </Button>
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </Box>
  );
}
