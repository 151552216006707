import React from 'react'
import "./footer.css";
import Logo from "../../assets/logo/logo.svg"
import { Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocation, faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <footer>
            <div className='container'>
                <div className='footer-logo'>
                    <img src={Logo} />
                </div>
                <div className='footer-container'>
                    <div className='d-block'>
                        <Link target='_blank' to="https://www.google.com/maps/dir//delta+servis/@41.0341013,28.82509,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14cab9ea79191589:0x1b9a6b7f14ff7ec3!2m2!1d28.864644!2d40.996189" className='location-body hover-div'>
                            <FontAwesomeIcon className="mt-2 ml-2" size="lg" icon={faMapLocation} />
                            <Text fz={18} ml={8} w={300}>Bahçelievler Mahallesi Mehmetçik Sokak Kadir Has Sitesi No:1/1/123 Kat:5 Bahçelievler / İstanbul</Text>
                            <div className='footer-div'>
                            </div>
                        </Link>
                        <Link target='_blank' to="mailto:info@deltafix.com.tr" className='mail hover-div'>
                        <FontAwesomeIcon className="mt-1 ml-2" size="lg" icon={faMailBulk} />                            
                        <Text className='mail-text' fz={18}>info@deltafix.com.tr</Text>
                        </Link>
                        <Link target='_blank' to="tel:08505320500" className='call hover-div'>
                        <FontAwesomeIcon className="mt-1 ml-2" size="lg" icon={faPhone} />
                            <Text className='call-text' fz={18}>0(850) 532 05 00</Text>
                        </Link>
                    </div>
                    <div className='footer-menu'>
                        <Text color='#ed1651' fz={18}>Kategoriler</Text>
                        <Text color='#ed1651' fz={18}>Hizmetler</Text>
                        <Text color='#ed1651' fz={18}>Online İşlemler</Text>
                    </div>
                    <div className='socia-icons'>
                        <Text className='social-text' color='#ed1651' fz={18} mb={35}>Bizi sosyal medya üzerinden takip edin.</Text>
                        <div className='social-list'>
                            <Link to="https://www.facebook.com/deltaservis/"><FontAwesomeIcon className="ml-2 hover-icon" size="2xl" icon={faFacebook} /></Link>
                            <Link to="https://www.facebook.com/deltaservis/"><FontAwesomeIcon className="ml-2 hover-icon" size="2xl" icon={faInstagram} /></Link>
                            <Link to="https://www.facebook.com/deltaservis/"><FontAwesomeIcon className="ml-2 hover-icon" size="2xl" icon={faLinkedin} /></Link>
                            <Link to="https://www.facebook.com/deltaservis/"><FontAwesomeIcon className="ml-2 hover-icon" size="2xl" icon={faTwitter} /></Link>
                            <Link to="https://www.facebook.com/deltaservis/"><FontAwesomeIcon className="ml-2 hover-icon" size="2xl" icon={faWhatsapp} /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
