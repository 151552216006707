import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Care from "./pages/phonecare/Care";
import Servicefee from "./pages/servicefee/Servicefee";
import Service from "./pages/services/Service";
import Login from "./pages/user/login/Login";
import LoginDealer from "./pages/user/loginDealer/LoginDealer";
import Register from "./pages/user/register/Register";
import Profile from "./pages/user/profile/Profile";
import ForgotPassword from "./pages/user/forgotPassword/ForgotPassword";
import DeviceShipping from "./pages/deviceshippin/DeviceShipping";
import NotFound from "./pages/notFound/NotFound";
import ScrollToTop from "./utilities/ScrollToTop";
import RemoveToken from "./utilities/RemoveToken";
import ProductInquiry from "./pages/user/ProductInquiry/ProductInquiry";
import Development from "./pages/development/development";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Products />} />
        <Route path="/products" element={<Products />} />
        <Route path="/care/:type/:id" element={<Care />} />
        <Route path="/service-repair/:id" element={<Servicefee />} />
        <Route path="/service" element={<Service />} />
        <Route path="/send-product" element={<DeviceShipping />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/login-dealer" element={<LoginDealer />} />
        <Route path="/user/register" element={<Register />} />
        <Route path="/user/account" element={<Profile />} />
        <Route path="/product-inquiry" element={<ProductInquiry />} />
        <Route path="/home" element={<Home />} />
        <Route path="/forgot-password/:token" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <RemoveToken />
      <ScrollToTop />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
