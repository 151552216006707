import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions/userActions";
import { useNavigate } from "react-router-dom";

function RemoveToken() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let now = new Date();
      if (currentUser?.expiredDate < now) {
        dispatch(setUser(null));
        navigate("/");
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentUser, dispatch, navigate]);

  return null;
}

export default RemoveToken;
