import React from 'react'
import "./slider.css";
import { Text } from '@mantine/core';
import video from "../../assets/videos/banner/banner.mp4";




export default function Slider() {
  return (
    <div className='banner-container'>
      <div className="container-banner">
        <div className="row banner-row">
          <div className='banner-col'>
            <Text className='guaranteed-reliable' fw={500} pt={150} w="100%">GARANTİLİ GÜVENİLİR,</Text>
            <Text className='banner-text'>PROFESYONEL ÇÖZÜM</Text>
          </div>
          <div className="video-section col">
            <div className='video'>
              <video className='fullscreen-video' autoPlay loop playsInline muted>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
