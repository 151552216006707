import React from 'react'
import './brand.css';
import Brand1 from '../../assets/brandicons/brand1.svg';
import Brand2 from  '../../assets/brandicons/brand2.svg'; 
import Brand3 from  '../../assets/brandicons/brand3.svg'; 
import Brand4 from  '../../assets/brandicons/brand4.svg'; 
import Brand5 from  '../../assets/brandicons/brand5.svg'; 
import Brand6 from  '../../assets/brandicons/brand6.svg'; 
import Brand7 from  '../../assets/brandicons/brand7.svg'; 
import Brand8 from  '../../assets/brandicons/brand8.svg'; 
import Brand9 from  '../../assets/brandicons/brand9.svg'; 
import Brand10 from  '../../assets/brandicons/brand10.svg'; 
import { Text } from '@mantine/core';



export default function Brand() {
  return (
    
    <div className='brand-container'>
       <div className='containers-body'>
        <Text className='service-body' color='#e51a4b' ta="center" fz={70} fw="bold">Hizmet verdiğimiz</Text>
        <Text className='brands-models' color='white' ta="center" fz={70} fw="bold">markalar ve modeller</Text>
    </div>
        <div className="brand-section container d-flex">
  <div className="brand-body1 col">
    <div className="row mb-2">
        <div className='brands-body-div'>
        <img src={Brand3}/>
        </div>
    </div>
    <div className="row">
    <div className='brands-body-div'>
        <img src={Brand4}/>
        </div>
    </div>
  </div>
  <div className="brand-body2 col">
    <div className="row mb-3">
        <div className='brands-body-div'>
        <img src={Brand1}/>
        </div>
    </div>
    <div className="row">
    <div className='brands-body-div'>
        <img src={Brand5}/>
        </div>
    </div>
  </div>
  <div className="brand-body1 col">
    <div className="row mb-3">
        <div className='brands-body-div'>
        <img src={Brand6}/>
        </div>
    </div>
    <div className="row">
    <div className='brands-body-div'>
        <img src={Brand7}/>
        </div>
    </div>
  </div>
  <div className="brand-body4 col">
    <div className="row mb-3">
        <div className='brands-body-div'>
        <img src={Brand2}/>
        </div>
    </div>
    <div className="row">
    <div className='brands-body-div'>
        <img src={Brand8}/>
        </div>
    </div>
  </div>
  <div className="brand-body1 col">
    <div className="row mb-3">
        <div className='brands-body-div'>
        <img src={Brand9}/>
        </div>
    </div>
    <div className="row">
    <div className='brands-body-div'>
        <img src={Brand10}/>
        </div>
    </div>
  </div>
</div>
    </div>
  )
}
