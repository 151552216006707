import React, { useState, useEffect } from 'react';
import EcosystemLogo from "../../assets/ecosystem/logoEcosystem.png";
import SpinnerInner from "../../assets/ecosystem/spinnerInner.webp";
import SpinnerOut from "../../assets/ecosystem/spinnerOut.webp";
import "./ecosystem.css";
import { Text } from '@mantine/core';

function Ecosystem() {
  const [spinnerOut, setSpinnerOut] = useState({
    transform: 'rotate(0deg)',
    transformOrigin: 'center',
  });
  const [spinnerInner, setSpinnerInner] = useState({
    transform: 'rotate(0deg)',
    transformOrigin: 'center',
  });

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const rotationAngle = scrollY / 12;
    const rotationNegativeAngle = -scrollY / 12;
    setSpinnerOut({
      transform: `rotate(${rotationAngle}deg)`,
      transformOrigin: 'center',
    });
    setSpinnerInner({
      transform: `rotate(${rotationNegativeAngle}deg)`,
      transformOrigin: 'center',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="fixWheelContainer">
      <div className="shadowRight"></div>
      <div className="shadowTop"></div>
      <div className="spinnerOut" style={spinnerOut}>
        <img src={SpinnerOut} alt="SpinnerOut" />
      </div>
      <div className="spinnerInner" style={spinnerInner}>
        <img src={SpinnerInner} alt="SpinnerIn" />
      </div>
      <div className="spinnerLogo">
        <img src={EcosystemLogo} alt="Ecosystem" />
        <Text className='ecosystem-text'>Ekosistemi</Text>
      </div>
    </div>
  );
}

export default Ecosystem;