import { SET_DEVICE, SET_SELECTED_DEVICE } from '../actionTypes/deviceActionTypes';

export const setDevice = (device) => {
  return {
    type: SET_DEVICE,
    payload: device,
  };
};

export const setSelectedDevice = (selectedDevice) => ({
  type: SET_SELECTED_DEVICE,
  payload: selectedDevice,
});