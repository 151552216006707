import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  Image,
  View,
} from "@react-pdf/renderer";
import Logo from "../../../assets/logo/pdfBanner.jpg";

function InquiryPdf(props) {
  const { row } = props;

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
        fontWeight: 300,
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
        fontWeight: 400,
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
        fontWeight: 500,
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Roboto",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 16,
      margin: 10,
      marginLeft: 30,
      fontWeight: "800",
    },
    text: {
      fontFamily: "Roboto",
      margin: 5,
      marginLeft: 40,
      fontSize: 12,
      textAlign: "justify",
    },
    textP2: {
      fontFamily: "Roboto",
      margin: 2,
      marginLeft: 25,
      marginRight: 30,
      fontSize: 10,
      textAlign: "justify",
    },
    HeaderP2: {
      fontFamily: "Roboto",
      margin: 5,
      marginTop: 40,
      fontSize: 16,
      textAlign: "center",
    },

    logo: {
      width: "95%",
      marginTop: 20,
      marginLeft: 10,
      marginRight: 10,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    box: {
      display: "block",
      margin: "20px",
      padding: "10px",
      border: "2px solid #73001c",
      borderRadius: "15px",
    },
    boxHeader: {
      display: "block",
      width: "100%",
      color: "#73001c",
      fontSize: "12px",
      marginLeft: "10px",
    },
    boxText: {
      marginTop: "5px",
      marginLeft: "5px",
      fontSize: "11px",
      display: "block",
      width: "100%",
    },
    boxTextFotter: {
      marginTop: "10px",
      textAlign: "center",
      fontSize: "12px",
      display: "block",
      width: "100%",
    },
    boxTextRed: {
      marginLeft: "5px",
      marginTop: "5px",
      fontSize: "11px",
      display: "block",
      color: "#73001c",
      width: "100%",
    },
    boxTextRedBold: {
      fontSize: "11px",
      display: "block",
      color: "#73001c",
      width: "100%",
      fontWeight: "bold",
    },
    boxTextRedUnderline: {
      fontSize: "11px",
      display: "block",
      color: "#73001c",
      width: "100%",
      textDecoration: "underline",
    },
    signArea: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
    signText: {
      flex: 1,
      fontSize: "14px",
      textAlign: "center",
    },
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        <Image style={styles.logo} source={Logo} alt="DeltaTurkcell" />
        <Text style={styles.subtitle}>Ürün Bilgisi</Text>
        <Text style={styles.text}>Form No: {row.id}</Text>
        <Text style={styles.text}>Marka / Model: {row.brandModel}</Text>
        <Text style={styles.text}>IMEI: {row.imei}</Text>
        <Text style={styles.subtitle}>Kulllanıcı Bilgisi</Text>
        <Text style={styles.text}>Müşteri Adı: {row.title}</Text>
        <Text style={styles.text}>Telefon: {row.phone}</Text>
        <Text style={styles.text}>Adres: {row.address}</Text>
        <Text style={styles.subtitle}>Onarım Bilgisi</Text>
        <Text style={styles.text}>Bayi Kabul Tarihi: {row.dealerAcceptedDate}</Text>
        <Text style={styles.text}>Fiziksel Durum: {row.physicalCondition}</Text>
        <Text style={styles.text}>Tahmini Teslim Süresi: 5 İş Günü</Text>
        <Text style={styles.text}>Yapılan İşlemler: {row.repairDesc}</Text>
        <Text style={styles.text}>Garanti Süresi: 6 Ay</Text>
        <View style={styles.box}>
          <Text style={styles.boxHeader}>Değerli Müşterilerimiz,</Text>
          <Text style={styles.boxText}>
            Onarım esnasında resim, ses dosyası, rehber vb. bilgileri
            silinebilir.
          </Text>
          <Text style={styles.boxText}>
            Kişisel bilgi güvenliğiniz size aittir, lütfen bilgilerinizi
            yedekleyiniz.
          </Text>
          <Text style={styles.boxText}>
            Cihazınızla birlikte teslim ettiğiniz aksesuarları, cihazı
            bırakırken ve teslim alırken kontrol ediniz.
          </Text>
          <Text style={styles.boxText}>
            Otomatik bilgilendirme için cep telefonu numaranızı veya e-posta
            adresinizi bırakmanız gerekmektedir.
          </Text>
          <Text style={styles.boxTextRed}>
            Cihazınızın durumunu
            <Text style={styles.boxTextRedUnderline}> deltafix.com.tr </Text>
            internet sitemizden sorgulayabilirsiniz.
          </Text>
          <Text style={styles.boxTextFotter}>
            Lütfen arka Sayfadaki
            <Text style={styles.boxTextRedBold}> Servis Sözleşmesini </Text>
            okuyunuz.
          </Text>
        </View>
        <View style={styles.signArea}>
          <Text style={styles.signText}>Teslim Eden</Text>
          <Text style={styles.signText}>Teslim Alan</Text>
        </View>
      </Page>
      <Page size="A4" wrap style={styles.page}>
        <View>
          <Text style={styles.HeaderP2}>
            HİZMET ŞARTLARI VE KARŞILIKLI YÜKÜMLÜLÜKLER
          </Text>
          <Text style={styles.textP2}>
            Cihaz bu belge karşılığı teslim edilecektir. Kaybetmemeye ve üçüncü
            şahısların eline geçmemesine özen gösteriniz. Aksi halde doğabilecek
            olumsuz durumlardan servis merkezimiz sorumlu tutulamaz.
          </Text>
          <Text style={styles.textP2}>
            Servis Merkezimize kargo, nakliye veya posta yoluyla gönderilen
            ürünler için nakil sırasında yolda doğabilecek hasar ve kayıplardan
            firmamız sorumlu tutulamaz. Lütfen gönderi fişlerinizi özenle
            saklayınız. Nakliye esnasında doğabilecek olumsuz durumlarda tüm
            sorumluluk kargo, posta ve nakliye firmasına ait olacaktır.
          </Text>
          <Text style={styles.textP2}>
            Arızalı ürünün onarımı veya değişimi sırasında kayıtlı müşteri
            ayarları ya da rehber kayıtları gibi bilgiler bir daha gelmeyecek
            şekilde silinebilir. Bu tip kişisel bilgilerin silinmesi halinde
            doğacak durumlardan servis merkezimiz sorumlu tutulamaz.
          </Text>
          <Text style={styles.textP2}>
            Üretici firmalarca onaylanmamış, yetkisiz servis noktaları ve
            kişilerce açılmış ve/veya mühdahale görmüş cihazlar ile
            kullanıcıların hatalı ya da kötü kullanımı sonucunda oluşan arızalar
            garanti kapsamı dışında değerlendirilir. Bu tür cihazların servis
            girişlerinin garanti dahili yapılmış olması durumu değiştirmez.
            Nihai karar servis merkezimizdeki laboratuvarlarımızda ve/veya ileri
            seviye onarım laboratuvarlarımızda yapılacak inceleme sonucu
            verilecektir.
          </Text>
          <Text style={styles.textP2}>
            Müşterimiz tarafından beyan edilen arızanın, ürünün servis kaydı
            yapılırken operatörümüz tarafında gözlemlenememesi durumunda nihai
            karar laboratuvarlarımızda yapılacak inceleme sonucu verilecektir.
          </Text>
          <Text style={styles.textP2}>
            Garanti harici durumlarda, onarım kabul edilmediği takdirde harcanan
            zaman ve emeğin bedeli olarak işçilik ücreti tahsil edilir.
          </Text>
          <Text style={styles.textP2}>
            Servis merkezimize teslim edilen garanti süresi dahilindeki
            ürünlerde servisimiz onarım için parça değişimi, arızalı bölümün
            komple değişimi ya da ürünün değiştirilmesi gibi yöntemlerden uygun
            olanını arızanın çözümüne göre seçer. Servis merkezlerimizde onarım
            yapılabilecek bir ürünün değiştirilmesi belirli yasal şartlar
            olmadan talep ve/veya teklif edilemez.
          </Text>
          <Text style={styles.textP2}>
            Servis merkezimize teslim edildiğinden itibaren 90 gün içerisinde
            teslim alınmayan ürünlerden dolayı oluşabilecek durumlarda
            sorumluluk servis merkezimize ait değildir.
          </Text>
          <Text style={styles.textP2}>
            SIVI TEMASLI ve ya DARBELİ CİHAZLAR: Sıvı ile temas ve ya darbe,
            cihazın onarım işlemleri sırasında farklı arızaların ortaya
            çıkmasına hatta cihazın çalışmamasına yol açabilir. Cihazın onarımı
            yapılsa dahi garanti harici kalır ve ileride aynı arıza
            tekrarlanabilir veya farklı arızalar oluşabilir. Böyle cihazlara
            onarım garantisi verilmez ve bu durumlardan servis merkezimiz
            sorumlu tutulamaz.
          </Text>
          <Text style={styles.textP2}>
            Kullanım klavuzuna aykırı kullanımlarda ürün garanti kapsamı dışında
            kalacaktır ve bu durumdan servis merkezimiz sorumlu tutulamaz.
          </Text>
          <Text style={styles.textP2}>
            Arızalı ürünler ile birlikte kesinlikle sim kart teslim edilmez.
            Teslim ettiğiniz diğer ürünleri lütfen bu formun ön yüzünde
            belirtiniz.
          </Text>
          <Text style={styles.textP2}>
            Teknik servisimiz TSE belgesine sahip olup, 6502 sayılı TKHK
            hükümleri ve ilgili mevzuat gereğine uygun olarak çalışmaktadır.
            Uyuşmazlık durumlarda bilgi işlem kayıtlarımız esas alınacaktır.
          </Text>
          <Text style={styles.textP2}>
            SERVİS İSTASYONLARININ SORUMLULUKLARI
          </Text>
          <Text style={styles.textP2}>
            Tüketicinin bulunduğu yerde servis istasyonunun olmaması halinde
            satış sonrası hizmetlerin verilmesinden, tüketiciye en yakın yerdeki
            servis istasyonu sorumludur.
          </Text>
          <Text style={styles.textP2}>
            Servis istasyonları, Madde 10 da belirtilen hususları içeren servis
            fişini düzenlemek ve bir nüshasını tüketicilere vermek zorundadır.
            Kullanım ömrü süresince, malın bakım ve/veya onarım süresi, ürünün
            servis merkezimize teslim tarihinden İtibaren (20) iş günü ile
            sınırlıdır. Servis istasyonları tarafından verilen montaj, bakım ve
            onarım hizmetiyle ilgili olarak, bir yıl içerisinde aynı arızanın
            tekrarı halinde herhangi bir servis ücreti alınmaz. Tüketicinin malı
            kullanım kılavuzunda yer alan hususlara aykırı kullanmasından
            kaynaklanan arızalar, bu madde kapsamı dışındadır.
          </Text>
          <Text style={styles.textP2}>
            Malın garanti süresi dışında, garanti belgesi ile satılması zorunlu
            olmayan bir parçasının servis istasyonu tarafından değiştirilmesi
            ve/veya satılması durumunda, değişen parça için altı aydan aşağı
            olmamak üzere bir garanti süresi verilir.
          </Text>
        </View>
      </Page>
    </Document>
  );

  console.log(MyDocument);

  return (
    <PDFViewer width="100%" height="700vh">
      <MyDocument />
    </PDFViewer>
  );
}

export default InquiryPdf;
