import React from 'react'
import Slider from "../../components/slider/Slider"
import Brand from "../../components/brandimg/Brand";
import RepairSlider from "../../components/repairslider/RepairSlider";
import Ecosystem from "../../components/ecosystem/Ecosystem";
import Categories from "../../components/categories/Categories";
import Blog from "../../components/blog/Blog";
import About from "../../components/about/About";
import İnformation from "../../components/information/İnformation";
import Contents from "../../components/contents/Contents";
import Where from "../../components/where/Where";
import Buyback from "../../components/buyback/Buyback";
import News from "../../components/news/News";
import Articles from "../../components/articles/Articles";
import Brands from '../../components/brands/Brands';;


export default function Home() {
  
  return (
    <>
      <Slider/>
      <Brand/>
      <RepairSlider/>
      <Ecosystem />
      <Categories />
      <Blog/>
      <About/>
      <İnformation/>
      <Contents/>
      <Where/>
      <Buyback/>
      <News/>
      <Articles/>
      <Brands/>
    </>
  )
}
