import { Text } from "@mantine/core";
import React from "react";
import "./about.css";
import economy from "../../assets/img/economy.webp";
import vacum from "../../assets/img/vacum.webp";
import watch from "../../assets/img/watch.webp";
import scooter from "../../assets/img/scooter.webp";

export default function About() {
  return (
    <div className="about-container container">
      <div className="row">
        <Text className="about-title" fw="bold" ta="center" fz={70} color="white">
          Neden Biz
        </Text>
        <Text ta="center" fz={18} color="#D1D1D1" mb={100}>
          Müşteri odaklı yaklaşımımız ve uzman kadromuzla neden bize güvenmeniz
          gerektiğini keşfedin
        </Text>
      </div>
      <div className="about-section text-center">
        <div className="about-section row">
          <div className="economy-container-section col-12 col-lg-4 border-radius-sm-1 mt-sm-custom-1">
            <div className="economy-container">
              <div className="row">
                <div className="col-6">
                  <Text className="text-box-title text-white">
                    <Text color="#ed1651" className="d-inline">
                      Eko
                    </Text>
                    nomik
                  </Text>
                </div>
                <div className="col-6 overflow-hidden">
                  <img className="economy-img" src={economy} alt="economy" />
                </div>
              </div>
            </div>
            <div className="guaranteed-container border-radius-sm-1 col-12 mt-sm-custom-1">
              <div className="col-6">
                <Text color="white" fz={72} pt={27} pl={20} ta="left">
                  Güvenli
                </Text>
              </div>
              <div className="col-6 overflow-hidden vacum-body-img">
                <img src={vacum} alt="Vacum" />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="team-container pt-5 border-radius-sm-1 mt-sm-custom-1">
              <Text fz={72} color="white" ta="left" pl={20} fw={500}>
                Profesyonel
              </Text>
              <div className="watch-img">
                <img src={watch} alt="Watch" />
              </div>
            </div>
          </div>
          <div className="service-container-section col-12 col-lg-4 mt-sm-custom-1">
            <div className="service-container overflow-hidden border-radius-sm-1">
              <div className="row">
                <div className="col-7">
                  <Text fz={72} color="white" fw={600} ta="left" pl={20}>
                    Hızlı
                  </Text>
                  <Text ta={"left"} color="#fff" pl={20} fz={17}>
                    Uzman teknisyenlerimiz ile cihazlarınızdaki tüm sorunları
                    tespit edip, yüksek teknolojik ekipmanlarımız ile hızlı ve
                    etkili bir şekilde onarırken müşteri memnuniyetini en üst
                    düzeye çıkarmayı hedefliyoruz. Her adımda kaliteyi ve
                    güvenilirliği ön planda tutarak en iyi hizmeti sunmayı
                    amaçlıyoruz.
                  </Text>
                </div>
                <div className="col-5">
                  <div className="scrooter-img">
                    <img src={scooter} alt="Scooter" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
