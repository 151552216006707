import React from "react";
import "./buyback.css";
import { Button, Grid, Text } from "@mantine/core";
import deltapc from "../../assets/img/deltapc.svg";
import deltafix from "../../assets/img/deltafix.svg";
import cargo from "../../assets/img/cargo.svg";
import fastCash from "../../assets/img/fastCash.svg";
import pcContainer from "../../assets/img/pcContainer.webp";
import paymentGif from "../../assets/img/mobile_payment.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Buyback() {
  return (
    <div className="buyback-container container">
      <Text
        className="buyback-title"
        color="white"
        fz={70}
        fw="bold"
        ta="center"
      >
        BuyBack ile Tanışın!
      </Text>
      <Text className="buyback-text" fz={18} color="white" ta="center">
        Delta Buyback ile eski cihazlarını satmak çok kolay
      </Text>
      <Grid className="buyback-grid">
        <Grid.Col className="buyback-section" span={6}>
          <div className="device-container">
            <Text color="white" fz={40} fw="bold" ta="center" pt={55}>
              Eski Cihazını Yenile!
            </Text>
            <Text className="text-white px-2" fz={18} ta="center" pt={25}>
              Buyback avantajımızla, eski cihazlarınızı değerlendirebilir.
              Cihazınızı sıfırlayarak yeniden hayata geçiriyoruz.
            </Text>
            <div className="device-container-btn">
              <Button bg="#1E1E1E">
                <div className="delta-btn">
                  <Link
                    target="_blank"
                    to="https://buyback.deltaservis.com.tr/"
                  >
                    DeltaFix BuyBack{" "}
                    <FontAwesomeIcon size="lg" icon={faAnglesRight} />
                  </Link>
                </div>
              </Button>
            </div>
          </div>
          <Text fz={18} color="white" ta="center">
            <img
              className="payment-body w-100"
              src={paymentGif}
              alt="Ödeme"
            ></img>
          </Text>
        </Grid.Col>
        <Grid.Col className="payment-section" span={6}>
          <div className="payment-container">
            <div className="cargo-body free-cargo">
              <div className="w-100 p-1">
                <img src={cargo} />
                <Text pt={10} color="#fff" fz={38} fw="bold" ta="center">
                  Ücretsiz Kargo
                </Text>
              </div>
            </div>
            <div className="cargo-body fast-cash-back">
              <div className="w-100 p-1">
                <img src={fastCash} />
                <Text pt={10} color="#fff" fz={38} fw="bold" ta="center">
                  Hızlı Geri Ödeme
                </Text>
              </div>
            </div>
          </div>
          <div className="pc-container">
            <img src={pcContainer} />
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}
