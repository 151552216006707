import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import deviceReducer from './deviceReducers';
import userReducer from './userReducers';
import repairReducer from './repairReducers';


const persistConfig = {
  key: 'redux',
  storage,
};

const rootReducer = combineReducers({
  device: deviceReducer,
  user: userReducer,
  repair: repairReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;