import React from "react";
import "./where.css";
import { Grid, Text } from "@mantine/core";
import mapPhone from "../../assets/img/wp-phone.png";
import howtogo from "../../assets/img/howToGo.webp";
import call from "../../assets/img/call.svg";
import mail from "../../assets/img/mail.svg";
import wp from "../../assets/img/wp.svg";
import { Link } from "react-router-dom";
import wpVideo from "../../assets/videos/banner/WhatsApp-delta.mp4";

export default function Where() {
  return (
    <div className="where-container container">
      <div className="pt-5">
        <Text
          className="where-title"
          color="white"
          fz={70}
          fw="bold"
          ta="center"
          pt={100}
        >
          Neredeyiz?
        </Text>
        <Text color="#D1D1D1" fz={18} ta="center" pb={85}>
          Teknoloji sorunlarınız için bir adım ötedeyiz
        </Text>
      </div>
      <Grid className="where-grid">
        <Grid.Col span={4} className="where-body">
          <div className="inNearContainer">
            <Text color="white" fz={40} ta="center" pt={60}>
              Her Zaman
            </Text>
            <Text
              className="innear-title"
              color="white"
              fz={50}
              fw={700}
              ta="center"
            >
              Yanındayız
            </Text>
            <Text color="#D1D1D1" p={20} fz={18}>
              Sorularınız, sorunlarınız veya teknoloji ihtiyaçlarınızla ilgili
              her an destek alabileceğiniz bir ekibiz. Bizimle birlikte,
              güvenilir ve çözüm odaklı bir deneyim yaşayın.
            </Text>
          </div>
          <div className="phone-container">
            <Text fz={24} color="#fff" ta="center" fw={700} pt={47} pb={42}>
              Bir Telefon Kadar Yakınız
            </Text>
            <div className="contactContainerInner">
              <div className="contactCard">
                <div>
                  <div className="call-img">
                    <img src={call} alt="arama" />
                  </div>
                  <div>
                    <Text color="white" fz={16} fw="bold" ta="center">
                      Çağrı Merkezimiz
                    </Text>
                  </div>
                  <div>
                    <Link
                      className="contact-links"
                      ta="center"
                      target="_blank"
                      to="tel:08505320500"
                    >
                      0 (850) 532 05 00
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contactCard">
                <div>
                  <div className="call-img">
                    <img src={mail} alt="mail" />
                  </div>
                  <div>
                    <Text color="white" fz={16} fw="bold" ta="center">
                      E-Posta Adrsimiz
                    </Text>
                  </div>
                  <div>
                    <Link
                      className="contact-links"
                      ta="center"
                      target="_blank"
                      to="info@deltaservis.com.tr"
                    >
                      info@deltaservis.com.tr
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contactCard">
                <div>
                  <div className="call-img">
                    <img src={wp} alt="whatsapp" />
                  </div>
                  <div>
                    <Text color="white" fz={16} fw="bold" ta="center">
                      WhatsApp Destek Hattımız
                    </Text>
                    <Link
                      className="contact-links"
                      ta="center"
                      target="_blank"
                      to="https://api.whatsapp.com/send?phone=908505320500&text=DeltaFix%20ile%20ilgili%20bir%20sorum%20var"
                    >
                      0 (850) 532 05 00
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col className="delta-message-body" span={4}>
          <div className="delta-message-img">
            <video className="w-100 h-100" autoPlay playsInline muted>
              <source src={wpVideo} type="video/mp4" />
            </video>
            <img src={mapPhone} alt="Delta Mesaj" />
          </div>
        </Grid.Col>
        <Grid.Col className="map-ada-body" span={4}>
          <div className="map-ada-img">
            <iframe
              title="google-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.3280282216747!2d28.859773087892954!3d40.996192946314736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9ea79191589%3A0x1b9a6b7f14ff7ec3!2sDelta%20Servis!5e0!3m2!1str!2str!4v1700657255292!5m2!1str!2str"
              width="100%"
              height="700px"
            ></iframe>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}
