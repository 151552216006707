import { Text } from "@mantine/core";
import React from "react";
import "./categories.css";
import Categories1 from "../../assets/img/categories1.webp";
import Categories2 from "../../assets/img/categories2.webp";
import Categories3 from "../../assets/img/categories3.webp";
import Categories4 from "../../assets/img/categories4.webp";
import Categories5 from "../../assets/img/categories5.webp";
import Categories6 from "../../assets/img/categories6.webp";

export default function Categories() {
  return (
    <div className="categories-container">
      <Text fz={70} color="white" ta="center" fw="bold">
        Sizin İçin Kategorilerimiz
      </Text>
      <div className="d-flex justify-content-center">
        <Text className="w-50" fz={15} color="#D1D1D1" ta="center" pb={75}>
          İhtiyaçlarınıza uygun çözümleri sunmak için özenle oluşturulan
          kategorilerimiz ve geniş onarım yelpazemizle her kullanıcıya hitap
          ediyoruz. Size özel çözümlerimizle teknoloji deneyiminizi en üst
          seviyeye çıkarmak için buradayız.
        </Text>
      </div>
      <div className="shadowContainer">
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories1} />
            {/* <Text className='categories-title'>CEP TELEFONLARI</Text> */}
          </div>
        </div>
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories2} />
            {/* <Text className='categories-title-body'>ROBOT SÜPÜRGE</Text> */}
          </div>
        </div>
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories3} />
            {/* <Text className='categories-title-body'>Elektirikli Scooter</Text> */}
          </div>
        </div>
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories4} />
            {/* <Text className='categories-title-body'>Dikey Süpürge</Text> */}
          </div>
        </div>
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories5} />
            {/* <Text className='categories-title-body'>Akıllı Saat</Text> */}
          </div>
        </div>
        <div className="categories-item">
          <div className="multiply">
            <img className="categories-img" src={Categories6} />
            {/* <Text className='categories-title-body'>Bluetooth Hoparlör</Text> */}
          </div>
        </div>
      </div>
    </div>
  );
}
