import { Text } from "@mantine/core";
import React from "react";
import "./information.css";
import ScreenRes from "../../assets/img/screenRes.webp";
import sss from "../../assets/img/sss.svg";

export default function İnformation() {
  return (
    <div className="information-container container">
      <div>
        <Text fz={70} fw="bold" color="white" ta="center">
          Öne Çıkanlar
        </Text>
        <Text fz={18} color="#d1d1d1" ta="center">
          Profesyonel ve kaliteli çözümler
        </Text>
      </div>
      <div className="screen-container-div">
        <div className="row screen-section">
          <div className="col-8 screen-container">
            <div className="col-9">
              <Text fz={52} color="white" fw={500} pl={20} pt={35}>
                Teknolojinin Işıltısını
              </Text>
              <Text
                fz={65}
                color="white"
                className="custom-area-title"
                fw={700}
                pl={15}
                pt={0}
              >
                Geri Getiriyoruz
              </Text>
              <Text fz={20} color="white" pl={20}>
                Ekran parlatma ile cihazlarının ekranını ilk günkü haline
                getiriyoruz.
              </Text>
            </div>
            <div className="col-3 screen-img">
              <img src={ScreenRes} />
            </div>
          </div>
          <div className="col-4 orijinal-div">
            <div className="orijinal-container">
              <div>
                <Text fz={42} color="#ED1651" fw={500} pl={25} pt={36}>
                  Teknolojiyi Yeniden
                </Text>
                <Text
                  fz={50}
                  color="#ED1651"
                  className="custom-area-title pt-0 mt-0"
                  fw={700}
                  pl={25}
                  pr={15}
                  pt={25}
                  pb={10}
                >
                  Canlandırıyoruz
                </Text>
                <Text fz={18} color="#D1D1D1" pl={26} pr={4}>
                  Onarım sonrası cihazlarınız ilk günkü orijinalliğine geri
                  dönüyor.
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="orjinal-contanier gradient-contanier">
              <div>
                <Text color="#fff" fz={55} fw={500} pl={15} pt={75}>
                  Her Çatlakta
                </Text>
                <Text
                  className="custom-area-title pt-0 mt-0"
                  color="#fff"
                  fz={55}
                  fw={700}
                  pl={15}
                  pt={15}
                >
                  Tecrübemiz Parlar
                </Text>
              </div>
            </div>
          </div>
          <div className="col-7 sss-container">
            <div className="text-center col-8 pt-5">
              <Text
                fz={70}
                className="d-inline"
                color="white"
                fw={800}
                pl={15}
                pt={15}
              >
                S
                <Text
                  className="d-inline"
                  fz={60}
                  color="white"
                  fw={500}
                  pt={15}
                >
                  ıkça
                </Text>
              </Text>

              <Text
                fz={70}
                className="d-inline"
                color="white"
                fw={800}
                pl={15}
                pt={15}
              >
                S
                <Text
                  className="d-inline"
                  fz={60}
                  color="white"
                  fw={500}
                  pt={15}
                >
                  orulan
                </Text>
              </Text>
              <Text fz={70} color="white" fw={800} pl={15} pt={15}>
                S
                <Text
                  className="d-inline"
                  fz={60}
                  color="white"
                  fw={500}
                  pt={15}
                >
                  orular
                </Text>
              </Text>
            </div>
            <div className="sss-img col-4">
              <img className="w-100" src={sss} alt="Sıkça sorulan sorular" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
