import React, { useEffect, useState } from "react";
import "./servicefee.css";
import { Button, Switch, Text } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  setAddFix,
  setRemoveFix,
  setRemoveFuse,
  setAddFuse,
} from "../../redux/actions/repairsActions";
import PriceFixer from "../../utilities/priceFixer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faAnglesRight,
  faAnglesLeft,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import VerticalSlider from "../user/verticalSlider/VerticalSlider";

export default function Servicefee() {
  const dispatch = useDispatch();
  const { device, repair } = useSelector((state) => state);
  const [fixsApi, setFixsApi] = useState([]);
  const [fusesApi, setFusesApi] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);
  const { id } = useParams();
  const [totalQuestionPrice, setTotalQuestionPrice] = useState(0);

  useEffect(() => {
    const allItems = [...repair.fixes, ...repair.fuses];
    const total = allItems.reduce((acc, currentItem) => {
      return acc + parseInt(currentItem.question_price, 10);
    }, 0);

    setTotalQuestionPrice(total);
  }, [repair]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const article = {
          apikey: "5745321453468",
          model_id: id,
        };
        const response = await axios.post(
          `https://omega.deltaservis.com.tr/fix/getModelFixs/`,
          article
        );
        setFixsApi(response.data.fixes);
        setFusesApi(response.data.fuses);

        const initialSwitchStates = [];
        fusesApi.forEach((fuse) => {
          initialSwitchStates[fuse.id] = repair.fuses.some(
            (item) => item.id === fuse.id
          );
        });
        setSwitchStates(initialSwitchStates);
      } catch (error) {
        console.error("Hata oluştu: ", error);
      }
    };

    fetchData();
  }, [id]);

  function isAddedFuses(fusesdId) {
    const addedList = repair.fuses.map((item) => item.id);
    return addedList.indexOf(fusesdId) > -1;
  }

  function isAddedFixes(fixedId) {
    const addedList = repair.fixes.map((item) => item.id);
    return addedList.indexOf(fixedId) > -1;
  }

  return device ? (
    <>
      <div className="container text-center">
        <div className="servicefee-section-div row mb-3">
          <Text className="service-repair-title">
            <h2>
              {device.brand_name} {device.selectedDevice.model_name} Online
              Servis Ücreti Sorgulama
            </h2>
            Cihazınızın sorunlarını işaretleyip tahmini arıza bedelimizi
            öğrenebilirsiniz. Burada verilen değerler bilgi amaçlıdır. Cihaz
            teknisyenlerimiz tarafından incelendikten sonra arızanın türüne göre
            değişiklik gösterebilir.
          </Text>
          <div className="col-12 col-md-8">
            <div className="repair-list">
              <div className="row">
                {fixsApi.length ? (
                  fixsApi.map((fix, index) => (
                    <div className="col-md-4 col-lg-3 col-6 mb-4" key={index}>
                      <div className="card-container card m-0">
                        <div className="card-info-wrapper">
                          <div className="card-info">
                            <FontAwesomeIcon size="lg" icon={faQuestion} />
                          </div>
                          <div className="card-info-desc">
                            {fix.description}
                          </div>
                        </div>
                        <div className="card-image">
                          <img src={fix.image} alt="Servis"></img>
                        </div>
                        <div className="card-title">{fix.title}</div>
                        {isAddedFixes(fix.id) ? (
                          <Button
                            className="remove-to-basket"
                            onClick={() => {
                              dispatch(setRemoveFix(fix));
                            }}
                          >
                            {fix.question_price !== "0" ? (
                              <PriceFixer price={fix.question_price} />
                            ) : (
                              " Bilgi Alınız "
                            )}
                          </Button>
                        ) : fix.question_status !== "0" ? (
                          <Button
                            className="add-to-basket"
                            onClick={() => {
                              dispatch(setAddFix(fix));
                            }}
                          >
                            Ekle
                          </Button>
                        ) : (
                          <Button className="add-to-basket passive">
                            Bu modelde bulunmamaktadır
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-white">
                    Bu ürüne ait bir onarım henüz bulunmuyor.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="device-img-about">
              <img
                className="w-100 pt-2"
                src={device.selectedDevice.model_photo}
                alt="Selected-product"
              />
              <Text className="device-title" color="#FFFFFF" fz={14} fw={500}>
                {device.selectedDevice.model_name}
              </Text>
            </div>
            <Link to={`/care/${device?.category_id}/${device?.brand_id}`}>
              <div className="change-model-div">
                <Button className="change-model transform-none">
                  <FontAwesomeIcon size="lg" icon={faAnglesLeft} />
                  <Text>Model Değiştir</Text>
                </Button>
              </div>
            </Link>
            <div className="col-12 repair-list-area">
              <div className="repair-title pt-3">Cihazın Genel Durumu:</div>
              <div className="scroll-list">
                {repair.fixes.length ? (
                  repair.fixes.map((fix, index) => (
                    <div key={index} className="d-flex py-1">
                      <div className="camera-title-buttons">
                        <span
                          onClick={() => {
                            dispatch(setRemoveFix(fix));
                          }}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={faClose}
                            className="remove-in-list"
                          />
                        </span>
                        <Text>{fix.title}</Text>
                      </div>
                      <div className="camera-price-buttons">
                        <Text className="fee-information text-white">
                          {fix.question_price !== "0" ? (
                            <PriceFixer price={fix.question_price} />
                          ) : (
                            " - "
                          )}
                        </Text>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="unselected-list">
                    Seçili onarım bulunmuyor.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container bg-grey">
          <div className="row">
            <div className="col-12 col-sm-8 pt-2">
              {fusesApi.map((fuse) => (
                <div className="row-item-fuse">
                  <Text fz={22} color="#FFFFFF">
                    {fuse.title}
                  </Text>
                  <Text color="#3fc100" fz={24} ta="left">
                    {<PriceFixer price={fuse.question_price} />}
                  </Text>
                  <div>
                    <Switch
                      checked={isAddedFuses(fuse.id) ? "checked" : null}
                      x
                      onChange={() => {
                        const updatedSwitchStates = [...switchStates];

                        if (updatedSwitchStates.indexOf(fuse.id) > -1) {
                          dispatch(setRemoveFuse(fuse));
                          updatedSwitchStates.splice(
                            updatedSwitchStates.indexOf(fuse.id),
                            1
                          );
                        } else {
                          updatedSwitchStates.push(fuse.id);
                          dispatch(setAddFuse(fuse));
                        }
                        setSwitchStates(updatedSwitchStates);
                      }}
                      className="service-switch"
                      color="pink"
                      size="md"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-sm-4 totally-area">
              <div className="w-100">
                <Text className="totally">
                  <div>Toplam Tutar</div>
                  <span className="price-text">
                    {<PriceFixer price={totalQuestionPrice.toString()} />}
                  </span>
                </Text>
              </div>
            </div>
            <div className="col-12">
              <div>
                <Link to={repair.fixes.length ? "/send-product" : "#"}>
                  <Button className="total-next transform-none">
                    <Text className="text-white mb-1 mx-1 h5">Devam Et</Text>
                    <div className="d-flex" style={{ display: "flex" }}>
                      <FontAwesomeIcon size="xl" icon={faAnglesRight} />
                    </div>
                  </Button>
                </Link>
                <div>
                  <Text color="#FFFFFF" ta="left" fz={12} pb={5} pt={25}>
                    Lütfen forma devam edebilmek için en az bir arıza seçiniz.
                  </Text>
                  <Text color="#FFFFFF" ta="left" fz={12} pb={25} pt={5}>
                    "Devam Et" butonuna basarak cihazınızı onarım için bize
                    ulaştırma seçeneklerine erişebilirsiniz.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
{/*         <div className="row">
          <div className="col-12 description-area">
            <div className="title">Dolor Sit Amet</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </div>
          </div>
        </div> */}
      </div>
      {/* <VerticalSlider></VerticalSlider> */}
    </>
  ) : (
    <div className="phone-section">
      <div className="mantine-Text-root phone-text">
        Önce Marka ve cihaz tipi seçiniz
      </div>
    </div>
  );
}
