import { SET_DEVICE, SET_SELECTED_DEVICE } from '../actionTypes/deviceActionTypes';

const initialState = null;

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE:
      return action.payload;
    case SET_SELECTED_DEVICE:
      return {
        ...state,
        selectedDevice: action.payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;