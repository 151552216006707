import React from "react";
import "./news.css";
import { Button, Text } from "@mantine/core";
import Slider from "react-slick";
import news1 from "../../assets/img/news1.svg";
import news2 from "../../assets/img/news2.svg";
import news3 from "../../assets/img/new3.svg";
import right from "../../assets/img/right.svg";

export default function News() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 5,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 6000,
    cssEase: "linear",
    appendDots: dots => (
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
          style={{
            width: "25px",
            color: "white",
            background:"#9F0F36",
            borderRadius:"6px",
            position:"relative",
            top:"40px"
          }}
        >
          {i + 1}
        </div>
      )
  };
      

  return (
    <div className="news-slider container">
      <div>
        <Text fz={70} color="#ED1651" fw="bold" ta="center">
          Haberler
        </Text>
        <Text color="#D1D1D1" fz={18} pb={80} ta="center">
        En güncel teknoloji haberleri ve firmamızla ilgili yenilikleri takip edin.
        </Text>
      </div>
      <div>
        <Slider key="news-slider" {...settings}>
          <div>
            <div className="news1-container">
              <img width="100%" src={news1} alt="news1" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="news1-container">
              <img width="100%" src={news2} alt="news2" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="news1-container">
              <img width="100%" src={news3} alt="news3" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="news1-container">
              <img width="100%" src={news1} alt="news4" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="news1-container">
              <img width="100%" src={news2} alt="news5" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="news1-container">
              <img width="100%" src={news3} alt="news6" />
              <div>
                <Text color="#ED1651" fz={22} pt={36} pl={15}>
                  Curabitur at lobortis massa, quis rutrum
                </Text>
                <Text fz={18} color="#D1D1D1" pl={15}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur at lobortis massa, quis rutrum nisi. Nulla
                  pellentesque nulla quis metus ultricies molestie.
                </Text>
                <div className="slider-btn">
                  <Button>Devam</Button>
                  <img
                    className="right-icon"
                    width={20}
                    height={20}
                    src={right}
                  />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
