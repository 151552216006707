import { Text } from '@mantine/core'
import React from 'react'
import "./brands.css";
import icon1 from "../../assets/img/icon1.svg";
import icon2 from "../../assets/img/icon2.svg";
import icon3 from "../../assets/img/icon3.svg";
import icon4 from "../../assets/img/icon4.svg";
import icon5 from "../../assets/img/icon5.svg";
import icon6 from "../../assets/img/icon6.svg";

export default function Brands() {
  return (
    <div className='brands-container'>
      <Text color='#FFFFFF' fz={70} ta="center">Daima Yanımızda Olanlar</Text>
      <Text fz={18} color='#D1D1D1' ta="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text>
      <div className='brand-logo-img'>
        <img src={icon1}/>
        <img src={icon2}/>
        <img src={icon3}/>
        <img src={icon4}/>
        <img src={icon5}/>
        <img src={icon6}/>
      </div>
    </div>
  )
}
