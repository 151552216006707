import React from 'react'
import "./contents.css";
import { Text } from '@mantine/core';
import batteryImage from "../../assets/img/battery.gif";
import screenImage from "../../assets/img/screen.gif";
import electricImage from "../../assets/img/electric.gif";
import roboticImage from "../../assets/img/robotic.gif";
import starImage from "../../assets/img/star.svg";
import { useState, useEffect } from 'react';


export default function Contents() {

  const [selectedItem, setSelectedItem] = useState(0);

  const itemList = [
    { id: 0, class:'star', text: 'Ekran Parlatma', url: starImage },
    { id: 1, class:'battery', text: 'Batarya Değişimi', url: batteryImage },
    { id: 2, class:'screen', text: 'Ekran Değişimi', url: screenImage },
    { id: 3, class:'electric', text: 'Anakart Onarımı', url: electricImage },
    { id: 4, class:'robotic', text: 'Robot Süpürge Onarımı', url: roboticImage },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedItem((prevSelectedItem) => (prevSelectedItem < 4 ? prevSelectedItem + 1 : 0));
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='contents-container container'>
        <Text fz={70} fw="bold" color='white' ta="center">Daha Daha?</Text>
        <Text fz={18} color='#d1d1d1' ta="center" mt={10} pb={150}>İhtiyaçlarınıza özel çözümlerimizle cihazlarınızı en iyi şekilde yeniden hayata geçiriyoruz.</Text>
        <div className="contain">
          <div className="animationContainer">
            <img
              className={itemList[selectedItem].class}
              src={itemList[selectedItem].url}
              alt={itemList[selectedItem].text}
              style={{ width: '100%' }}
            />
          </div>
          <div className="buttonContainer">
            {itemList.map((button, index) => (
              <div
                key={button.id}
                className={`buttonItem ${selectedItem === index ? 'selected' : ''}`}
                onClick={() => (setSelectedItem(button.id))}
              >
                {button.text}
              </div>
            ))}
          </div>
        </div>
    </div>

  )
}
