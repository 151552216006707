import React from "react";
import { Button, Text } from "@mantine/core";
import onarim from "../../assets/img/onarim_img.png";
import "./service.css";

export default function Service() {
  return (
    <div className="service-section">
      <div className="container text-center" style={{ paddingTop: "120px" }}>
        <div className="servicebody row">
          <div className="service-img-body col-5">
            <div style={{ height: "100%" }}>
              <img src={onarim} alt="onarim-img" style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
          <div className="service-img-body col-7">
            <div>
              <Text className="warranty-reliable" color="#B2B2B2" fz={42} ta="left">
                GARANTİLİ, GÜVENİLİR,
              </Text>
              <Text className="professional-solution" color="#E51A4B" fz={42} ta="left" fw="bold">
                PROFESYONEL ÇÖZÜM
              </Text>
              <Text color="#D1D1D1" ta="left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur non nisl iaculis metus venenatis interdum a vitae
                dolor. Suspendisse potenti. Etiam condimentum ultrices
                consectetur. Nam vitae eleifend turpis, eget sollicitudin eros.
                Nunc eu nunc scelerisque, blandit dui nec, hendrerit mauris. Sed
                in eros blandit, malesuada metus in, dapibus ante. Ut ac
                molestie risus, ac volutpat metus. Suspendisse potenti.
                Pellentesque nec velit ipsum. Vestibulum et turpis erat.
                Pellentesque a ante vitae velit faucibus maximus at vel enim.
                Nam vitae eleifend turpis, eget sollicitudin eros. Nunc eu nunc
                scelerisque, blandit dui nec, hendrerit mauris. Sed in eros
                blandit, malesuada metus in, dapibus ante. Ut ac molestie risus,
                ac volutpat metus. Suspendisse potenti
              </Text>
              <Button className="service-btn" w="100%" h="60px" mt={162}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Text color="#D1D1D1" fz={32} mr={20}>Detaylı bilgi için </Text>
                  <svg xmlns="http://www.w3.org/2000/svg" width="50.643" height="33.278" viewBox="0 0 56.643 59.278">
                    <g id="Group_177" data-name="Group 177" transform="translate(-143.722 1850.836) rotate(-90)">
                      <path id="GO_BACK_ARROW" data-name="GO BACK ARROW" d="M9.938,29.639,31.726,52.05a4.32,4.32,0,0,1,0,5.988,4.036,4.036,0,0,1-5.822,0l-24.7-25.4a4.32,4.32,0,0,1,0-5.988L25.9,1.24a4.036,4.036,0,0,1,5.822,0,4.32,4.32,0,0,1,0,5.988Z" transform="translate(1791.558 200.365) rotate(-90)" fill="#fff" />
                      <path id="GO_BACK_ARROW-2" data-name="GO BACK ARROW" d="M9.938,29.639,31.726,52.05a4.32,4.32,0,0,1,0,5.988,4.036,4.036,0,0,1-5.822,0l-24.7-25.4a4.32,4.32,0,0,1,0-5.988L25.9,1.24a4.036,4.036,0,0,1,5.822,0,4.32,4.32,0,0,1,0,5.988Z" transform="translate(1791.558 176.654) rotate(-90)" fill="#fff" />
                    </g>
                  </svg>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="servicebodydiv container text-center" style={{ marginTop: "120px" }}>
        <div className="row">
          <div className="service-div col-7">
            <Text ta="left" color="#D1D1D1" fz={20}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nisl iaculis metus venenatis interdum a vitae dolor. Suspendisse potenti. Etiam condimentum ultrices consectetur.
              Nam vitae eleifend turpis, eget sollicitudin eros. Nunc eu nunc scelerisque, blandit dui nec, hendrerit mauris. Sed in eros blandit, malesuada metus in, dapibus ante. Ut ac molestie risus, ac volutpat metus. Suspendisse potenti. Pellentesque nec velit ipsum. Vestibulum et turpis erat. Pellentesque a ante vitae velit faucibus maximus at vel enim.
              Nam vitae eleifend turpis, eget sollicitudin eros. Nunc eu nunc scelerisque, blandit dui nec, hendrerit mauris. Sed in eros blandit, malesuada metus in, dapibus ante. Ut ac molestie risus, ac volutpat metus. Suspendisse potenti
            </Text>
            <Button className="service-btn" w="100%" h="60px" mt={40}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Text color="#D1D1D1" fz={32} mr={20}>Detaylı bilgi için </Text>
                <svg xmlns="http://www.w3.org/2000/svg" width="30.643" height="33.278" viewBox="0 0 56.643 59.278">
                  <g id="Group_177" data-name="Group 177" transform="translate(-143.722 1850.836) rotate(-90)">
                    <path id="GO_BACK_ARROW" data-name="GO BACK ARROW" d="M9.938,29.639,31.726,52.05a4.32,4.32,0,0,1,0,5.988,4.036,4.036,0,0,1-5.822,0l-24.7-25.4a4.32,4.32,0,0,1,0-5.988L25.9,1.24a4.036,4.036,0,0,1,5.822,0,4.32,4.32,0,0,1,0,5.988Z" transform="translate(1791.558 200.365) rotate(-90)" fill="#fff" />
                    <path id="GO_BACK_ARROW-2" data-name="GO BACK ARROW" d="M9.938,29.639,31.726,52.05a4.32,4.32,0,0,1,0,5.988,4.036,4.036,0,0,1-5.822,0l-24.7-25.4a4.32,4.32,0,0,1,0-5.988L25.9,1.24a4.036,4.036,0,0,1,5.822,0,4.32,4.32,0,0,1,0,5.988Z" transform="translate(1791.558 176.654) rotate(-90)" fill="#fff" />
                  </g>
                </svg>
              </div>
            </Button>
          </div>
          <div className="service-div-col col-5">
            <div className="service-div-img" style={{ height: "100%" }}>
              <img src={onarim} alt="onarim-img" style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="service-general-body container" style={{ marginTop: "100px", paddingBottom: "100px" }}>
        <Text className="service-div-text" fz={34} color="#D1D1D1" pb={38}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nisl iaculis metus venenatis interdum a vitae dolor. Suspendisse potenti. Etiam condimentum ultrices consectetur. </Text>
        <Text className="service-div-text-body" fz={22} color="#D1D1D1">Nam vitae eleifend turpis, eget sollicitudin eros. Nunc eu nunc scelerisque, blandit dui nec, hendrerit mauris. Sed in eros blandit, malesuada metus in, dapibus ante. Ut ac molestie risus, ac volutpat metus. Suspendisse potenti. Pellentesque nec velit ipsum. Vestibulum et turpis erat. Pellentesque a ante vitae velit faucibus maximus at vel enim.
          Nam vitae eleifend turpis, eget sollicitudin eros. Nunc eu nunc scelerisque, blandit dui nec, hendrerit mauris. Sed in eros blandit, malesuada metus in, dapibus ante. Ut ac molestie risus, ac volutpat metus. Suspendisse potenti</Text>
      </div>
    </div>
  );
}
