import { Text } from "@mantine/core";
import React from "react";
import "./blog.css";
import repair from "../../assets/img/repair.svg";
import image1 from "../../assets/img/image1.svg";
import faultDetection from "../../assets/img/faultDetection.svg";
import phn from "../../assets/img/phn.png";

export default function Blog() {
  return (
    <div className="blog-container">
      <Text className="blog-title" fz={80} fw="bold" color="white" ta="center" pt={100}>
        Neler Yapıyoruz
      </Text>
      <Text fz={18} color="#D1D1D1" ta="center">
        Teknoloji sorunlarınıza hızlı ve güvenilir çözümler sunuyoruz
      </Text>
      <div className="container text-center">
        <div className="quick-repair-body row">
          <div className="col-4 repair">
            <img src={repair} alt="Repair" />
            <Text className="quickrepair-title" fz={52} color="#FFFFFF">
              Hızlı Onarım
            </Text>
            <Text
              className="quick-repair-div"
              color="#D1D1D1"
              fz={22}
              ta="center"
            >
              30 dakikada cihazlarınızı onarıyoruz.
            </Text>
            <div className="repair-img">
              <img src={image1} alt="Blog-1" />
            </div>
          </div>
          <div className="col-8 repair-warranty-body">
            <div className="repair-warranty">
              <div className="warranty-title col-12 col-md-8">
                <Text
                  className="repair-solution"
                  fz={52}
                  color="#00b0cc"
                  ta="left"
                  pl={20}
                >
                  Onarım Garantisi
                </Text>
                <Text
                  className="repair-solution-text"
                  color="#D1D1D1"
                  fz={20}
                  ta="left"
                  pl={20}
                  pt={10}
                >
                  Onarımını gerçekleştirdiğimiz cihazlarınıza Delta Servis
                  güvencesiyle garanti verilmektedir.
                </Text>
              </div>
              <div className="warrantyy-img">
                <img src={phn} alt="PHN" />
              </div>
            </div>
            <div className="repair-warranty-container">
              <div class="warranty-title col-12 col-md-8">
                <Text
                  className="online-fault-title"
                  fz={52}
                  ta="left"
                  pl={20}
                  color="#ED1651"
                >
                  Online Arıza Tespit
                </Text>
                <Text
                  className="repair-solution-text"
                  color="#D1D1D1"
                  fz={20}
                  ta="left"
                  pl={20}
                >
                  Özel yazılımlarımız ile arızalarınızı uzaktan tespit ediyoruz.
                </Text>
              </div>
              <div className="warranty-img">
                <img src={faultDetection} alt="Blog-Fault Detection" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
