import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import React, { useState } from "react";
import "./login.css";
import loginimg from "../../../assets/img/login.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faAnglesRight } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        setError("E-posta ve şifre alanları boş bırakılamaz.");
        return;
      }

      const article = {
        email: email,
        password: password,
        apikey: "5745321453468",
      };
      const response = await axios.post(
        `https://omega.deltaservis.com.tr/fix/login/`,
        article
      );

      if (response.data.code === 200) {
        let data = response.data.data;
        data.type = "user";
        data.expiredDate = new Date(new Date().getTime() + 50 * 60000);
        dispatch(setUser(data));
        navigate("/");
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="login-section text-center">
        <div className="login-body row m-0">
          <div className="col">
            <img className="login-img" src={loginimg} alt="login" />
          </div>
          <div className="col">
            <div>
              <Text color="#E51A4B" fz={42}>
                Kullanıcı Girişi
              </Text>
            </div>
            <div>
              <Container size={420} my={40}>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                  <TextInput
                    placeholder="E-posta"
                    required
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <PasswordInput
                    placeholder="Şifre"
                    required
                    mt="md"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {error && <Text color="#ff0000">{error}</Text>}
                  <Group justify="space-between" mt="lg">
                    <Checkbox color="gray" label="Beni Hatırla" />
                  </Group>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className="login-btn"
                      fullWidth
                      mt="xl"
                      onClick={handleLogin}
                    >
                      Giriş
                      <div className="icon-register">
                        <FontAwesomeIcon size="lg" icon={faAnglesRight} />
                      </div>
                    </Button>
                  </div>
                  <Link to="/user/register">
                    <Button className="register-btn">
                      <Text>Yeni Üyelik Oluştur</Text>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="icon-register">
                          <FontAwesomeIcon size="lg" icon={faPlus} />
                        </div>
                      </div>
                    </Button>
                  </Link>
                </Paper>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
