import { Link } from "react-router-dom";
import "./notfound.css";
import React from "react"

export default function NotFound() {

  return (

      <div className="stars">
          <div className="central-body">
              <img className="image-404" src="http://salehriaz.com/404Page/img/404.svg" alt="notFound" width="300px"></img>
              <Link to="/" className="btn-go-home">Anasayfaya Dön</Link>
          </div>
          <div className="objects">
              <img className="object_rocket" src="http://salehriaz.com/404Page/img/rocket.svg" alt="object_rocket" width="40px"></img>
              <div className="earth-moon">
                  <img className="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" alt="object_earth" width="100px"></img>
                  <img className="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" alt="object_moon" width="80px"></img>
              </div>
              <div className="box_astronaut">
                  <img className="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" alt="object_astronaut" width="140px"></img>
              </div>
          </div>
          <div className="glowing_stars">
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
              <div className="star"></div>
          </div>
      </div>
    );
}
