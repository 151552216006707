import { Button, Text } from "@mantine/core";
import "./care.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { setSelectedDevice } from "../../redux/actions/deviceActions";
import { setRepairs } from "../../redux/actions/repairsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faRemove } from "@fortawesome/free-solid-svg-icons";

export default function Care() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const device = useSelector((state) => state.device);

  const removeFilter = () => {
    setSearchTerm("");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = models.filter((items) => {
    return items.model_name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const { id, type } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const article = {
          apikey: "5745321453468",
          brand_id: id,
          category_id: type,
        };
        const response = await axios.post(
          `https://omega.deltaservis.com.tr/fix/getModels/`,
          article
        );
        setModels(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Hata oluştu: ", error);
      }
    };

    fetchData();
  }, [id]);

  const selectModel = (item) => {
    dispatch(setRepairs());
    dispatch(setSelectedDevice(item));
    navigate("/service-repair/" + item.model_id);
  };

  return (
    <div className="phone-section">
      {device ? (
        <>
          <div className="container pt-4">
            <div className="d-flex justify-content-between align-items-baseline">
              <div>
                <Text className="page-title">
                  {device.brand_name} Tamir / Bakım / Onarım Fiyatları
                </Text>
                <Text className="sub-title">
                  Lütfen ürününüzün modelinizi seçiniz.
                </Text>
              </div>
              <div className="d-flex">
                <div className="search-box-icon">
                  {!searchTerm ? (
                    <FontAwesomeIcon
                      className="ml-2"
                      size="lg"
                      color="#6e6e6e"
                      icon={faMagnifyingGlass}
                    />
                  ) : (
                    <Link to="#" onClick={removeFilter}>
                      <FontAwesomeIcon
                        className="ml-2"
                        size="lg"
                        color="#6e6e6e"
                        icon={faRemove}
                      />
                    </Link>
                  )}
                </div>

                <input
                  onChange={handleSearch}
                  className="search-box"
                  placeholder="Modeli yazarak aratabilirsiniz."
                ></input>
              </div>
            </div>
            <div className="row gap-4 py-3">
              {loading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : filteredData.length ? (
                filteredData.map((items) => (
                  <div
                    className="col-3 col-fix"
                    onClick={() => {
                      selectModel(items);
                    }}
                  >
                    <div key={items.model_id} className="card-container">
                      <Text color="#FFFFFF" fz={16} ta="center" mt={26}>
                        {items.model_name}
                      </Text>
                      <div
                        className="care-img"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "246px",
                        }}
                      >
                        <img
                          src={items.model_photo}
                          alt={items.model_name}
                          width={198}
                          height={246}
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <Text color="#FFFFFF" fz={12} ta="center">
                        {items.model_name} Onarım
                      </Text>
                      <Text color="#FFFFFF" fz={12} ta="center">
                        Teknik Servis ve Arıza Tespit
                      </Text>
                      <Button className="service-button">
                        <Text color="#FFFFFF" fz={14}>
                          Servis Ücretleri
                        </Text>
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h5 text-white">
                  Arama Sonucu "{searchTerm}" bulunamadı.
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="mantine-Text-root phone-text">
          Önce Marka ve cihaz tipi seçiniz
        </div>
      )}
    </div>
  );
}
