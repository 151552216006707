import {
  TextInput,
  Text,
  Group,
  Button,
  Container,
  PasswordInput,
  Paper,
  NativeSelect,
} from "@mantine/core";
import { IMaskInput } from "react-imask";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginimg from "../../../assets/img/login.png";
import "./register.css";
import { useForm, isEmail, hasLength } from "@mantine/form";
import city from "../../../utilities/city.json";
import district from "../../../utilities/district.json";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions/userActions";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  var [formResponse, setFormResponse] = useState("");

  const cityOptions = city.map(function (item) {
    return {
      label: item.city_name,
      value: item.city_id,
    };
  });

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const isValid = await form.validate();
      if (!isValid.hasErrors) {
        const unmaskedPhone = form.values.phone.replace(/\D/g, "");

        const article = {
          email: form.values.email,
          password: form.values.password,
          apikey: "5745321453468",
          name: form.values.name,
          city: form.values.city,
          district: form.values.district,
          surname: form.values.lastname,
          phone: unmaskedPhone,
        };
        const response = await axios.post(
          "https://omega.deltaservis.com.tr/fix/register/",
          article
        );
        setFormResponse(response.data.message);
        const user = {
          id: response.data.data,
          name: article.name,
          surname: article.surname,
          token: response.data.token,
        };
        dispatch(setUser(user));
        navigate("/");
      }
    } catch (error) {
      setFormResponse(error.response.data.message);
    }
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    const selectedCityObject = city.find((item) => item.city_id === cityId);
    setSelectedCity(selectedCityObject);
  };

  useEffect(() => {
    let filteredDistricts = district
      .filter((district) => district.city_id === selectedCity?.city_id)
      .map((filteredDistrict) => ({
        label: filteredDistrict.district_name,
        value: filteredDistrict.dist_id,
      }));
    setFilteredDistrict(filteredDistricts);
  }, [selectedCity]);

  const form = useForm({
    initialValues: {
      name: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      city: "",
      district: "",
      confirmpassword: "",
    },

    validate: {
      name: hasLength(
        { min: 2, max: 10 },
        "Ad 2-10 karakter uzunluğunda olmalıdır."
      ),
      lastname: hasLength(
        { min: 2, max: 10 },
        "Soyad 2-10 karakter uzunluğunda olmalıdır."
      ),
      email: isEmail("E-mail zorunludur."),
      password: hasLength({ min: 8 }, "Şifre en az 8 karakterden oluşmalıdır."),
      phone: (value) => {
        let unmaskValue = value.replace(/\D/g, "");
        const isValid =
          unmaskValue.startsWith("0") && unmaskValue.length === 11;
        return isValid ? null : "Telefon numarası geçersiz.";
      },
      city: (value) => (value ? null : "İl alanı zorunludur."),
      district: (value) => (value ? null : "İlçe alanı zorunludur."),
      confirmpassword: (value, values) =>
        value !== values.password ? "Şifreler Eşleşmedi." : null,
    },
  });

  return (
    <div>
      <div className="register-section text-center">
        <div className="container">
          <div className="register-body row m-0">
            <div className="col d-none d-lg-block">
              <img className="login-img" src={loginimg} alt="Login-img" />
            </div>
            <div className="col register-form-wrapper">
              <div>
                <Text color="#E51A4B" fz={42}>
                  Yeni Hesap Oluştur
                </Text>
              </div>
              <div>
                <Container
                  className="register-form"
                  component="form"
                  onSubmit={onSubmitHandler}
                >
                  <Paper withBorder shadow="md" p={15} mt={10} radius="md">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextInput
                        placeholder="Ad"
                        w={"100%"}
                        withAsterisk
                        {...form.getInputProps("name")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextInput
                        placeholder="Soyad"
                        withAsterisk
                        w={"100%"}
                        mt="md"
                        mb={"md"}
                        {...form.getInputProps("lastname")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <TextInput
                        component={IMaskInput}
                        mask="0 (000) 000 00 00"
                        className="phone-input"
                        withAsterisk
                        mt="md"
                        country={"tr"}
                        maxLength={11}
                        placeholder="Telefon"
                        {...form.getInputProps("phone")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <TextInput
                        placeholder="E-Posta"
                        withAsterisk
                        mt="md"
                        w={"100%"}
                        {...form.getInputProps("email")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <NativeSelect
                        placeholder="Şehir Seçiniz"
                        withAsterisk
                        w={"100%"}
                        data={cityOptions}
                        {...form.getInputProps("city")}
                        onBlur={handleCityChange}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <NativeSelect
                        placeholder="İlçe Seçiniz"
                        {...form.getInputProps("district")}
                        withAsterisk
                        w={"100%"}
                        data={filteredDistrict}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <PasswordInput
                        placeholder="Şifre"
                        withAsterisk
                        w={"100%"}
                        mt="md"
                        {...form.getInputProps("password")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PasswordInput
                        placeholder="Şifre Tekrar"
                        withAsterisk
                        mt="md"
                        w={"100%"}
                        {...form.getInputProps("confirmpassword")}
                      />
                      <Text className="required">*</Text>
                    </div>
                    <Group className="buttons-wrapper">
                      <Button
                        className="register-buton col-4"
                        type="submit"
                        disabled={!form.isValid}
                      >
                        <Text>Hesabımı Oluştur</Text>
                      </Button>
                      <Button className="already-register-buton col-4">
                        <Link to="/user/login">
                          <Text>Zaten üye misin?</Text>
                        </Link>
                      </Button>
                    </Group>
                    <Group justify="flex-end" mt="md">
                      <Text className="form-response">{formResponse}</Text>
                    </Group>
                  </Paper>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
