import { PasswordInput, Paper, Text, Container, Button } from "@mantine/core";
import React, { useState } from "react";
import "./forgotPassword.css";
import loginimg from "../../../assets/img/login.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

export default function ForgotPassword() {
  const { token } = useParams();

  const [password, setPassword] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (password) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const article = {
      password: "Gb136110*",
      apikey: "5745321453468",
    };
    try {
      const response = await axios.post(
        `https://omega.deltaservis.com.tr/fix/resetPassword/`,
        article,
        { headers }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="login-section text-center">
        <div className="login-body row m-0">
          <div className="col">
            <img className="login-img" src={loginimg} alt="login" />
          </div>
          <div className="col">
            <div>
              <Text color="#E51A4B" fz={42}>
                Şifremi Unuttum
              </Text>
            </div>
            <div>
              <Container size={420} my={40}>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                  <Text color="#E51A4B">Lütfen yeni şifre belirleyiniz.</Text>
                  <PasswordInput
                    placeholder="Yeni Şifre"
                    required
                    mt="md"
                    name="password"
                  />
                  <PasswordInput
                    placeholder="Tekrar Yeni Şifre"
                    required
                    mt="md"
                    name="password"
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className="login-btn"
                      fullWidth
                      mt="xl"
                      onClick={handleLogin(password)}
                    >
                      Şifreyi Değiştir
                      <div>
                      <FontAwesomeIcon className="ml-2" size="lg" icon={faAnglesRight} />

                      </div>
                    </Button>
                  </div>
                </Paper>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
