import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./productinquiry.css";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Pdf from "./InquiryPdf";
import Modal from "react-modal";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

export default function ProductInquiry() {
  const { token } = useSelector((state) => state.user) || { token: null };
  const [dealerInquiry, setDealerInquiry] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [takipNo, setTakipNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modalImages, setModalImages] = useState([]);

  const customStyles = {
    content: {
      top: "52%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "920px",
    },
  };

  const openImageModal = (images) => {
    setModalImages(images);
    openModal();
  };

  const showAlert = (status, id) => {
    let responseTR =
      status === "requestCanceled"
        ? "'İptal Edildi'"
        : status === "customerConfirmed"
        ? "Talep durumunu 'Fiyat Onaylandı'"
        : status === "customerDenied"
        ? "Talep durumunu 'Fiyat Onaylanmadı'"
        : status === "deliveryCompleted"
        ? "Talep durumu 'Bayiye Teslim Edildi'"
        : status === "requestCompleted"
        ? "Talep durumu 'Ürün Müşteriye Teslim Edildi'"
        : "";

    let statusTr =
      status === "requestCanceled"
        ? "Onarım talebini iptal etmeyi onaylıyor musunuz?"
        : status === "customerConfirmed"
        ? "Onarımı onaylıyor musunuz?"
        : status === "customerDenied"
        ? "Onarımı reddediyor musunuz?"
        : status === "deliveryCompleted"
        ? "Ürünü teslim almak istiyor musunuz?"
        : status === "requestCompleted"
        ? "Ürünü müşteriye teslim etmek istiyor musunuz?"
        : "";
    Swal.fire({
      title: "Talep No " + id + " için işlem onayı",
      text: statusTr,
      icon: "question",
      type: "warning",
      confirmButtonText: "Evet",
      cancelButtonText: "Vazgeç",
      showConfirmButton: true,
      showCancelButton: true,
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        editRequest(status, id);
        Swal.fire({
          title: "İşlem Başarılı!",
          text:
            "Talep No " +
            id +
            " için, " +
            responseTR +
            " olarak durum değiştirildi.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          text: "İşlem iptal edildi.",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      }
    });
  };

  const tableStyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        color: "#fff",
      },
    },
    cells: {
      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: "true",
    },
    {
      name: "Müşteri Adı",
      selector: (row) => (
        <span className="text-capitalize white-space-none">
          {row.title ? row.title : "-"}
        </span>
      ),
      center: "true",
      sortable: true,
    },
    {
      name: "Telefon",
      selector: (row) =>
        row.phone ? (
          <a className="show-phone" href={"tel:+9" + row.phone}>
            <FontAwesomeIcon size="sm" icon={faPhone} /> {row.phone}
          </a>
        ) : (
          "-"
        ),
      sortable: true,
      center: "true",
    },
    {
      name: "Marka / Model",
      selector: (row) => (
        <div>
          {row.brandModel ? row.brandModel : "-"}
          {row.images.length > 0 ? (
            <button
              className="pt-2 text-info show-images"
              onClick={() => openImageModal(row.images)}
            >
              Ürün Giriş Görselleri
            </button>
          ) : null}
        </div>
      ),
      sortable: true,
    },
    {
      name: "IMEI",
      selector: (row) => (row.imei ? row.imei : "-"),
      sortable: true,
      center: "true",
    },
    {
      name: "Seçili Arızalar",
      selector: (row) => (
        <span className="m-2 white-space-none">
          {JSON.parse(row.fix).map((item, index) => (
            <div className="py-1" key={index}>
              {item.title}
            </div>
          ))}
        </span>
      ),
    },
    {
      name: "Ön Tutar",
      selector: (row) =>
        row.total_price && row.total_price ? row.total_price + ".00 ₺" : "-",
      selector: (row) =>
        row.total_price
          ? Math.floor(row.total_price).toLocaleString() +
            "," +
            (row.total_price % 1).toFixed(2).substring(2) +
            " ₺"
          : "-",
      sortable: true,
      center: "true",
    },
    {
      name: "Son Tutar",
      selector: (row) =>
        row.last_price
          ? Math.floor(row.last_price).toLocaleString() +
            "," +
            (row.last_price % 1).toFixed(2).substring(2) +
            " ₺"
          : "-",
      sortable: true,
      center: "true",
    },
    {
      name: "Talep Durumu",
      selector: (row) => (
        <span className="text-capitalize white-space-none">{row.status}</span>
      ),
      center: "true",
    },
    {
      name: "İşlem",
      center: "true",
      cell: (row) =>
        row.status === "Teslim Alınacak" ? (
          <div className="d-block">
            <button
              onClick={() => {
                setSelectedRow(row);
                setModalImages(null);
                openModal();
              }}
              className="btn d-block btn-success w-100 m-2"
            >
              PDF Yazdır
            </button>
            <button
              onClick={() => showAlert("requestCanceled", row.id)}
              className="btn btn-danger w-100 m-2"
            >
              İptal Et
            </button>
          </div>
        ) : row.status === "Müşteri onay bekleniyor" ? (
          <div>
            <button
              onClick={() => showAlert("customerConfirmed", row.id)}
              className="btn btn-success w-100 m-2"
            >
              Onayla
            </button>
            <button
              onClick={() => showAlert("customerDenied", row.id)}
              className="btn btn-danger w-100 m-2"
            >
              Reddet
            </button>
          </div>
        ) : row.status === "Bayi kabul bekliyor" ? (
          <button
            onClick={() => showAlert("deliveryCompleted", row.id)}
            className="btn btn-primary w-100 m-2"
          >
            Teslim Al
          </button>
        ) : row.status === "Müşteriye teslim edilecek" ? (
          <button
            onClick={() => showAlert("requestCompleted", row.id)}
            className="btn btn-primary w-100 m-2"
          >
            Teslim Et
          </button>
        ) : (
          ""
        ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Kayıt Göster",
    rangeSeparatorText: "Arasındaki Kayıtlar Gösteriliyor / Toplam Kayıt: ",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tümü",
  };

  const fetchData = useCallback(async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const article = { apikey: "5745321453468" };
      const response = await axios.post(
        "https://omega.deltaservis.com.tr/fix/showTransactions/",
        article,
        { headers }
      );
      const responseData = response.data.data;
      setDealerInquiry(responseData);
      setFilter(responseData.reverse());
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }
  }, [token]);

  const editRequest = async (requestType, request_id) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const article = {
        apikey: "5745321453468",
        action: requestType,
        request_id: request_id,
      };
      await axios.post(
        "https://omega.deltaservis.com.tr/fix/editRequest/",
        article,
        { headers }
      );

      if (token) {
        fetchData();
      }
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }
  };

  const handleInquirySubmit = async (e) => {
    e && e.preventDefault();
    if (takipNo && phoneNumber) {
      try {
        const article = {
          apikey: "5745321453468",
          request_id: takipNo,
          phone: phoneNumber,
        };

        const response = await axios.post(
          "https://omega.deltaservis.com.tr/fix/showTransaction/",
          article
        );
        const responseData = [];
        responseData.push(response.data.data);
        setDealerInquiry(responseData);
        setFilter(responseData.reverse());
      } catch (error) {
        console.error("Hata oluştu: ", error);
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchData(token);
    }
  }, [token, fetchData]);

  useEffect(() => {
    if (dealerInquiry) {
      const result = dealerInquiry.filter((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] === "string") {
            if (item[key].toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
          }
        }

        return false;
      });
      setFilter(result);
    }
  }, [search, dealerInquiry, token]);

  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        {modalImages ? (
          <>
            <button className="close-button" onClick={closeModal}>
              X
            </button>
            <div className="container">
              <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                {modalImages.map((image, index) => (
                  <a href={image}>
                    <img
                      className="modal-device-images"
                      alt={"Ürün Görseli: " + image}
                      src={image}
                    />
                  </a>
                ))}
              </LightGallery>
            </div>
          </>
        ) : (
          <>
            <button className="close-button" onClick={closeModal}>
              X
            </button>
            <Pdf row={selectedRow} />
          </>
        )}
      </Modal>
      <div className="container y-5">
        <div className="row">
          <div className="col-12">
            <h2 className="text-white pt-5 pb-2">Online Sorguluma</h2>
          </div>
          <form className="d-flex">
            <div className="col-4">
              <input
                type="tel"
                className="form-control w-100 search-input mr-5"
                id="takipNo"
                aria-describedby="emailHelp"
                placeholder="Takip No"
                value={takipNo}
                striped="true"
                onChange={(e) => setTakipNo(e.target.value)}
              ></input>
            </div>
            <div className="col-5 px-2">
              <input
                type="tel"
                className="form-control w-100 search-input"
                id="phoneNumber"
                placeholder="Telefon Numarası"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              ></input>
            </div>
            <div className="col-3">
              <button
                type="submit"
                onClick={handleInquirySubmit}
                className="text-white service-button w-100"
              >
                Sorgula
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-12 mt-5">
            {token || filter.length ? (
              <DataTable
                customStyles={tableStyle}
                columns={columns}
                title="Onarım Listesi"
                data={filter}
                pagination
                fixedHeader
                responsive
                highlightOnHover
                subHeader
                striped
                paginationComponentOptions={paginationComponentOptions}
                theme="dark"
                subHeaderComponent={
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hızlı Arama..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
                subHeaderAlign="right"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
