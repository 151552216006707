import React from "react";
import "./articles.css";
import { BackgroundImage, Box, Button, Center, Text } from "@mantine/core";
import Slider from "react-slick";

export default function Articles() {
  const settingss = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 6000,
    cssEase: "linear",
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul className="m-0"> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "25px",
          color: "white",
          background: "#9F4A00",
          borderRadius: "6px",
          position:"relative",
          top:"70px"
        }}
      >
        {i + 1}
      </div>
    ),
  };

  return (
    <div className="articles-slider container">
        <Text fz={70} color="#FF7700" fw="bold" ta="center">
          Makaleler
        </Text>
        <Text color="#D1D1D1" fz={18} pb={80} ta="center">
        Teknoloji dünyasındaki güncel konular, uzman görüşleri ve ipuçlarıyla dolu makalelerimizi keşfedin.
        </Text>
        <Slider className="settingss-div" key="articles-slider" {...settingss}>
            <Box h={500} mx="auto">
                <BackgroundImage
                  className="article-wrapper"
                  h="100%"
                  src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                  radius="20px"
                >
                  <div className="title articles">
                    <Text className="article-date">
                      2 Şubat '23 •
                    </Text>
                    <Text className="title article-autor">
                      Ahmet Mehmet
                    </Text>
                  </div>
                  <Text className="description" color="#D1D1D1" fz={18} pb={5}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur at lobortis massa...
                  </Text>
                  <Text color="#FF7700" fz={18}>
                    Apple, iPad Pro, iOS
                  </Text>
                </BackgroundImage>
              </Box>
            <Box h={500} mx="auto">
                <BackgroundImage
                  className="article-wrapper"
                  h="100%"
                  src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                  radius="20px"
                >
                  <div className="title articles">
                    <Text className="article-date">
                      2 Şubat '23 •
                    </Text>
                    <Text className="title article-autor">
                      Ahmet Mehmet
                    </Text>
                  </div>
                  <Text className="description" color="#D1D1D1" fz={18} pb={5}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur at lobortis massa...
                  </Text>
                  <Text color="#FF7700" fz={18}>
                    Apple, iPad Pro, iOS
                  </Text>
                </BackgroundImage>
              </Box>
            <Box h={500} mx="auto">
                <BackgroundImage
                  className="article-wrapper"
                  h="100%"
                  src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                  radius="20px"
                >
                  <div className="title articles">
                    <Text className="article-date">
                      2 Şubat '23 •
                    </Text>
                    <Text className="title article-autor">
                      Ahmet Mehmet
                    </Text>
                  </div>
                  <Text className="description" color="#D1D1D1" fz={18} pb={5}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur at lobortis massa...
                  </Text>
                  <Text color="#FF7700" fz={18}>
                    Apple, iPad Pro, iOS
                  </Text>
                </BackgroundImage>
              </Box>
            <Box h={500} mx="auto">
                <BackgroundImage
                  className="article-wrapper"
                  h="100%"
                  src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                  radius="20px"
                >
                  <div className="title articles">
                    <Text className="article-date">
                      2 Şubat '23 •
                    </Text>
                    <Text className="title article-autor">
                      Ahmet Mehmet
                    </Text>
                  </div>
                  <Text className="description" color="#D1D1D1" fz={18} pb={5}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur at lobortis massa...
                  </Text>
                  <Text color="#FF7700" fz={18}>
                    Apple, iPad Pro, iOS
                  </Text>
                </BackgroundImage>
              </Box>
            <Box h={500} mx="auto">
                <BackgroundImage
                  className="article-wrapper"
                  h="100%"
                  src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                  radius="20px"
                >
                  <div className="title articles">
                    <Text className="article-date">
                      2 Şubat '23 •
                    </Text>
                    <Text className="title article-autor">
                      Ahmet Mehmet
                    </Text>
                  </div>
                  <Text className="description" color="#D1D1D1" fz={18} pb={5}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur at lobortis massa...
                  </Text>
                  <Text color="#FF7700" fz={18}>
                    Apple, iPad Pro, iOS
                  </Text>
                </BackgroundImage>
              </Box>
        </Slider>
    </div>
  );
}
