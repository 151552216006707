import React, { useEffect, useState } from "react";
import { Button, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDevice } from "../../redux/actions/deviceActions";
import axios from "axios";
import "./products.css";

export default function Products() {
  const dispatch = useDispatch();
  useSelector((state) => state.device);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const article = { apikey: "5745321453468" };
      const response = await axios.post(
        "https://omega.deltaservis.com.tr/fix/getBrands/",
        article
      );
      setBrands(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSetDevice = (item) => {
    dispatch(setDevice(item));
  };

  return (
    <div className="phone-section">
      <div className="container pt-4">
        <Text className="page-title">Markalar</Text>
        <Text className="sub-title">Lütfen ürününüzün markasını seçiniz.</Text>
      </div>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        brands.map((category, key) => (
          <div>
            <Text key={key} className="cat-title">
              {category.category_title}
            </Text>
            <div
              className="container card-container-section"
              style={{ display: loading && "none" }}
            >
              {category.items.map((item) => (
                <Link
                  className="text-decoration-none"
                  to={`/care/${item.category_id}/${item.brand_id}`}
                >
                  <div key={item.brand_id} className="card-container">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "20px",
                        paddingBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <img
                        src={item.brand_logo}
                        alt="iphone-logo"
                        width={60}
                        height={60}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div
                      className="brands-img"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item.brand_phone_img}
                        alt="iphone14"
                        width={"100%"}
                        height={"160px"}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <Text color="#FFFFFF" fz={20} ta="center">
                      {item.brand_name}
                    </Text>
                    <Text color="#FFFFFF" fz={12} ta="center">
                      Teknik Servis ve Arıza Tespit
                    </Text>
                    {/* <Button className="service-about-btn" bg="#343434" w={240} h={40} mb={10} radius={12} mt={20}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Text color="#E51A4B" fz={14} mr={15}>
                          Nasıl Yapıyoruz?
                        </Text>
                        <img src={youtube} alt="youtube" />
                      </div>
                    </Button> */}
                    <Button
                      className="service-button"
                      onClick={() => handleSetDevice(item)}
                    >
                      <Text className="service-button-text">
                        Servis Ücretleri
                      </Text>
                    </Button>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
}
